import { Button, Card, Flex } from 'antd';
import { MainMenu } from '../../data/main-menu';
import { useCallback } from 'react';
import { updateModalAction } from '../../redux/slice/modalSlice';
import { useAppDispatch } from '../../hooks/redux';
import { ModelsEnum } from '../../@types/dashboard';
import AddIcon from '../../icons/AddIcon';

export const DashboardQuickAdditions = () => {
  const dispatch = useAppDispatch();
  const openModal = useCallback((type: string) => {
    switch (type) {
      case ModelsEnum.mandate.toLowerCase():
        dispatch(
          updateModalAction({
            data: {
              brokerage: 0,
              fee: 0,
              activity: 'off',
            },
            isAdd: true,
            type,
          }),
        );
        break;
      case ModelsEnum.enquiry.toLowerCase():
        dispatch(
          updateModalAction({
            data: null,
            isAdd: true,
            type,
          }),
        );
        break;
      case ModelsEnum.indication.toLowerCase():
        dispatch(
          updateModalAction({
            data: undefined,
            isAdd: true,
            type,
          }),
        );
        break;
      case ModelsEnum.deal.toLowerCase():
        dispatch(
          updateModalAction({
            data: null,
            isAdd: true,
            type: 'deals',
          }),
        );
        break;
      case ModelsEnum.note.toLowerCase():
        dispatch(
          updateModalAction({
            data: null,
            isAdd: true,
            type: 'notes',
          }),
        );
        break;
      case ModelsEnum.payment.toLowerCase():
        dispatch(
          updateModalAction({
            data: null,
            isAdd: true,
            type: 'payements',
          }),
        );
        break;
      case ModelsEnum.legalEntity.toLowerCase():
        dispatch(
          updateModalAction({
            data: null,
            isAdd: true,
            type,
          }),
        );
        break;
      case ModelsEnum.people.toLowerCase():
        dispatch(updateModalAction({ data: null, isAdd: true, type }));
        break;
    }
  }, []);
  return (
    <Card
      className="dashboard-table-wrapper"
      size="small"
      style={{ marginTop: 20 }}
    >
      <span className="base-card-title">Quick additions</span>
      <Flex
        wrap="wrap"
        justify="space-between"
        style={{ marginTop: 15, color: 'var(--Gris-fonc)' }}
      >
        {MainMenu.slice(0, MainMenu.length - 1).map(({ Icon, ...m }) => (
          <Flex align="center" key={m.key} vertical>
            <Flex align="end" style={{ width: 66 }} justify="end">
              <AddIcon
                onClick={() => openModal(m.key)}
                width={18}
                height={18}
                color="#1A171B"
              />
            </Flex>
            <Button
              type="default"
              onClick={() => openModal(m.key)}
              style={{
                height: 40,
                padding: 0,
                width: 40,
                borderRadius: 11.6,
                borderColor: 'var(--Rouge-vif-medium)',
              }}
            >
              {/**<img width={30} height={30} src={} />**/}
              <Icon width={30} height={30} />
            </Button>
            <div>{m.label}</div>
          </Flex>
        ))}
      </Flex>
    </Card>
  );
};
