import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NotificationState {
  title?: string | null;
  body?: string | null;
  show?: boolean;
}

const initialState: NotificationState = {
  title: '',
  body: '',
  show: false,
};

export const notificationSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    updateNotificationMessage: (
      state,
      action: PayloadAction<NotificationState>,
    ) => {
      state = action.payload;
      return state;
    },
  },
});

export const { updateNotificationMessage } = notificationSlice.actions;
export default notificationSlice.reducer;
