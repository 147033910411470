import React, { useEffect } from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import { ToastContainer, Zoom } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { AppRouter } from './routes';
import NotificationModal from './components/Modal/NotificationModal';
import { useLocation } from 'react-router-dom';
import { closeAllModal } from './redux/slice/modalSlice';

import { initSocket } from './utils/laravel-socket';
import { AppDialogs } from './components/Modal/AppDialogs';

initSocket();

const App = () => {
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const dispatch = useAppDispatch();
  const notification = useAppSelector((state) => state.notification);

  const { pathname } = useLocation();

  const ref = React.useRef<LoadingBarRef>(null);

  useEffect(() => {
    if (isLoading) {
      ref.current!.staticStart();
    } else {
      ref.current!.complete();
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(closeAllModal());
  }, [pathname]);

  return (
    <>
      <LoadingBar ref={ref} color="#eb5d57 " height={3} />
      <ToastContainer
        limit={1}
        transition={Zoom}
        autoClose={1500}
        closeOnClick
      />
      <NotificationModal
        show={notification.show}
        body={notification.body}
        title={notification.title}
      />

      {/* {AppRouter(routes)} */}
      <AppDialogs />

      <AppRouter />
    </>
  );
};

export default App;
