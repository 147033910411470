import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Method } from '../../../../@types/common-types';
import { ConterParty } from '../../../../@types/conterParty';
import { EntityDeals } from '../../../../@types/deals/deals';
import {
  EntityLimits,
  EntityParticipation,
  InputFormParticipation,
  InputFormParticipationByDeals,
  ParticipationResponse,
} from '../../../../@types/participation/participation';
import { People } from '../../../../@types/people';

import { baseApi } from '../../baseApi';
import { url } from '../../url';
import {
  InputFormLimits,
  LimiteResponse,
} from '../../../../@types/limit/types';

const participationEntity = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      participations: build.query<
        ParticipationResponse,
        {
          page: number | undefined;
          start: number | undefined;
          limit: number | undefined;
          sort?: string;
          filter?: string;
        }
      >({
        query: (queryParams) => {
          const { start, page, limit, sort, filter } = queryParams;
          return {
            url: url.participation.list,
            params: {
              start,
              page,
              limit,
              sort,
              filter,
              getDealNumber: true,
              getClientName: true,
              getObligorName: true,
            },
          };
        },
        providesTags: ['LIST_PARTICIPATIONS'],
      }),

      exportParticipations: build.mutation<
        string,
        { fields?: string[]; filter: any }
      >({
        query: (queryParams) => {
          return {
            url: url.participation.export,
            method: Method.POST,
            data: queryParams,
          };
        },
      }),

      getDealsById: build.mutation<
        { deal: EntityDeals; success: string },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.deals.list}/${id}`,
            params: {
              _dc: new Date().getTime(),
            },
          };
        },
      }),

      getConterPartyId: build.mutation<
        { counterparty: ConterParty; success: string },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.counterparty.list}/${id}`,
          };
        },
      }),

      getPeopleById: build.mutation<
        { people: People; success: string },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.people.list}/${id}`,
          };
        },
      }),

      updateParticipations: build.mutation<
        { success: boolean; participation: EntityParticipation },
        InputFormParticipation
      >({
        query: (body) => {
          return {
            url: url.participation.list + '/' + body.id,
            method: Method.PUT,
            data: body,
            params: {
              getDealNumber: true,
              getClientName: true,
              getObligorName: true,
            },
          };
        },
        invalidatesTags: [
          'LIST_PARTICIPATIONS',
          'PARTICIPATION_BY_DEALS',
          'GLOBAL_RESULT_SEARCH',
        ],
      }),

      updateParticipationsByDealId: build.mutation<
        { success: boolean; participation: EntityParticipation },
        {
          data: InputFormParticipationByDeals;
          dealId: number;
        }
      >({
        query: (body) => {
          return {
            url: url.participation.list + '/' + body.data?.id,
            method: Method.PUT,
            data: body.data,
            params: {
              dealId: body?.dealId,
              withUnderwriterEntity: true,
            },
          };
        },
        invalidatesTags: [
          'LIST_PARTICIPATIONS',
          'PARTICIPATION_BY_DEALS',
          'GLOBAL_RESULT_SEARCH',
        ],
      }),

      createParticipations: build.mutation<
        { success: boolean; participation: EntityParticipation },
        InputFormParticipation
      >({
        query: (body) => {
          return {
            url: url.participation.list,
            method: Method.POST,
            data: body,
            params: {
              dealId: body.deal_id,
              withUnderwriterEntity: true,
            },
          };
        },
        invalidatesTags: ['LIST_PARTICIPATIONS', 'PARTICIPATION_BY_DEALS'],
      }),

      deleteParticipations: build.mutation<
        { success: boolean; mandate: EntityParticipation },
        { id: number }
      >({
        query: (body) => {
          return {
            url: url.participation.list + '/' + body.id,
            method: Method.DELETE,
          };
        },
        invalidatesTags: [
          'LIST_PARTICIPATIONS',
          'PARTICIPATION_BY_DEALS',
          'GLOBAL_RESULT_SEARCH',
        ],
      }),

      getLimitsByParticipationId: build.query<
        { limit: any; success: string },
        { id: string | number; sort?: string }
      >({
        query: ({ id, sort }) => {
          return {
            url: `${url.limit.list}`,
            params: {
              _dc: new Date().getTime(),
              getType: 'participation_obligor',
              participationId: id,
              getGroup: true,
              sort,
              page: 1,
              start: 0,
              limit: 100,
            },
          };
        },
        providesTags: ['PARTICIPATIONS_OBLIGOR_LIMITS_LIST'],
      }),
      getLimitsByLimitId: build.query<
        { limit: any; success: string },
        { id: string | number; sort?: string }
      >({
        query: ({ id, sort }) => {
          return {
            url: `${url.limit.list}`,
            params: {
              _dc: new Date().getTime(),
              getType: 'participation_obligor',
              limitId: id,
              sort,
              page: 1,
              start: 0,
              limit: 100,
            },
          };
        },
        providesTags: ['PARTICIPATIONS_OBLIGOR_LIMITS_LIST'],
      }),
      getParticipationByDealId: build.mutation<
        { participation: Array<EntityParticipation>; success: string },
        { dealId: string | number; query?: string }
      >({
        query: ({ dealId, query }) => {
          return {
            url: `${url.participation.list}`,
            params: {
              _dc: new Date().getTime(),
              getType: 'participation_obligor',
              withUnderwriterName: true,
              dealId,
              query,
              page: 1,
              start: 0,
              limit: 100,
            },
          };
        },
      }),

      searchLimit: build.mutation<
        LimiteResponse,
        {
          query: string | undefined;
          dealId: number;
        }
      >({
        query: (body) => {
          const { query, dealId } = body;
          return {
            url: url.limit.list,
            params: {
              _dc: new Date().getTime(),
              query,
              dealId,
              getType: 'deal_obligor',
              page: 1,
              start: 0,
              limit: 200,
            },
            method: Method.GET,
          };
        },
      }),

      getLimitbyDealId: build.query<
        { limit: any; success: string },
        { id: string | number; sort?: string }
      >({
        query: ({ id, sort }) => {
          return {
            url: `${url.limit.list}`,
            params: {
              _dc: new Date().getTime(),
              getType: 'deal_obligor',
              dealId: id,
              sort,
              page: 1,
              start: 0,
              limit: 100,
            },
          };
        },
        providesTags: ['LIMITS_BY_DEALS'],
      }),

      contDealLimit: build.query<
        { nbr: number; success: boolean },
        {
          pId: number;
        }
      >({
        query: (body) => {
          const { pId } = body;
          return {
            url: url.limit_count + '/' + pId,
            params: {
              _dc: new Date().getTime(),
            },
            method: Method.GET,
          };
        },
      }),

      createParticipationDealLimit: build.mutation<
        {
          limit: EntityLimits;
          success: boolean;
        },
        { form: InputFormLimits; participationId: number }
      >({
        query: (queryParams) => {
          return {
            url: url.limit.edit,
            method: Method.POST,
            params: {
              getType: queryParams?.form?.type,
              participationId: queryParams.participationId,
            },
            data: queryParams.form,
          };
        },
        invalidatesTags: [
          'PARTICIPATIONS_OBLIGOR_LIMITS_LIST',
          'LIMITS_BY_DEALS',
        ],
      }),

      updateParticipationDealLimit: build.mutation<
        {
          limit: EntityLimits;
          success: boolean;
        },
        { form: InputFormLimits; participationId: number }
      >({
        query: (queryParams) => {
          return {
            url: url.limit.edit + '/' + queryParams.form.id,
            method: Method.PUT,
            params: {
              getType: queryParams?.form?.type,
              participationId: queryParams.participationId,
            },
            data: queryParams.form,
          };
        },
        invalidatesTags: [
          'PARTICIPATIONS_OBLIGOR_LIMITS_LIST',
          'GLOBAL_RESULT_SEARCH',
        ],
      }),

      deleteParticipationDealLimit: build.mutation<
        { success: boolean; limit: EntityLimits },
        { id: number }
      >({
        query: (body) => {
          return {
            url: url.limit.delete + '/' + body.id,
            method: Method.DELETE,
          };
        },
        invalidatesTags: [
          'PARTICIPATIONS_OBLIGOR_LIMITS_LIST',
          'LIMITS_BY_DEALS',
        ],
      }),

      getLimitById: build.mutation<
        { limit: any; success: string },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.limit.list}/${id}`,
            params: {
              _dc: new Date().getTime(),
            },
          };
        },
      }),
    };
  },
});

export const {
  useParticipationsQuery,
  useLazyParticipationsQuery,
  useExportParticipationsMutation,
  useGetDealsByIdMutation,
  useGetConterPartyIdMutation,
  useGetPeopleByIdMutation,
  useUpdateParticipationsMutation,
  useLazyGetLimitsByParticipationIdQuery,
  useLazyGetLimitsByLimitIdQuery,
  useDeleteParticipationsMutation,
  useGetParticipationByDealIdMutation,
  useCreateParticipationsMutation,
  useSearchLimitMutation,
  useGetLimitbyDealIdQuery,
  useContDealLimitQuery,
  useCreateParticipationDealLimitMutation,
  useDeleteParticipationDealLimitMutation,
  useUpdateParticipationDealLimitMutation,
  useGetLimitByIdMutation,
  useUpdateParticipationsByDealIdMutation,
} = participationEntity;
