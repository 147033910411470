import { useGetTermsQuery } from '../../redux/api/ws/terms/get';
import { Term } from '../../@types/terms';

type Props = {
  types: string[];
  excludes?: string[];
};
export const useTerms = ({ types, excludes }: Props) => {
  const { isLoading, data: termsData } = useGetTermsQuery(
    {
      types,
    },
    {
      refetchOnMountOrArgChange: false,
    },
  );

  const options = (termsData?.data || []).reduce((acc, item) => {
    if (excludes?.length) {
      if (excludes.includes(item.key)) {
        acc.push(item);
      }
    } else {
      acc.push(item);
    }
    return acc;
  }, [] as Term[]);

  return {
    isLoading,
    options,
  };
};
