import { SVGProps } from 'react';

const ArrowDropdownIcon = ({ color, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="9"
      height="5"
      viewBox="0 0 9 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.32746 0.373625C0.440245 0.260873 0.593195 0.197533 0.752675 0.197533C0.912154 0.197533 1.0651 0.260873 1.17789 0.373625L4.155 3.35073L7.13211 0.373625C7.24554 0.264069 7.39746 0.203447 7.55516 0.204817C7.71285 0.206188 7.8637 0.26944 7.97521 0.380951C8.08672 0.492462 8.14997 0.64331 8.15134 0.801005C8.15271 0.958699 8.09209 1.11062 7.98254 1.22406L4.58021 4.62638C4.46743 4.73913 4.31448 4.80247 4.155 4.80247C3.99552 4.80247 3.84257 4.73913 3.72978 4.62638L0.32746 1.22406C0.214708 1.11127 0.151367 0.958319 0.151367 0.79884C0.151367 0.639361 0.214708 0.486411 0.32746 0.373625Z"
        fill={color || '#851001'}
      />
    </svg>
  );
};

export default ArrowDropdownIcon;
