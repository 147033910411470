import { ColumnsType, ColumnType } from 'antd/es/table';
import React, { useEffect, useState, useContext } from 'react';
import {
  EntityNotes,
  NotesExport,
  NotesFilterType,
} from '../../../@types/segregatedaccounts/notes';
import {
  useDeleteNoteMutation,
  useExportNotesMutation,
  useNotesQuery,
} from '../../../redux/api/ws/segregatedaccounts/notes';
import FilterTab from '../../../utils/filter-tab';
import { ReactComponent as Edit } from '../../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../../assets/img/Delete.svg';
import * as bootstrap from 'bootstrap';
import moment from 'moment';
import {
  amountFormat,
  downloadCsv,
  exportedDropdownValue,
  handleAmount,
  isCan,
} from '../../../utils/helper-function';
import { FilterType } from '../../../@types';
import classes from './Notes.module.scss';
import { exportedNotes } from '../../../utils/exported-notes';
import { useAppDispatch } from '../../../hooks/redux';
import { updateNotificationMessage } from '../../../redux/slice/notificationSlice';
import { updateModalAction } from '../../../redux/slice/modalSlice';
import { Menu, TermConst } from '../../../@types/common-types';
import { ContextSegregated } from '../SegregatedContent';
import { debounce } from 'lodash';
import { EntityMandate } from '../../../@types/mandates/mandates';
import { EntityDeals } from '../../../@types/deals/deals';
import ArrowDropdownIcon from '../../../icons/ArrowDropdownIcon';
import ArrowUpIcon from '../../../icons/ArrowUpIcon';
import { useTerms } from '../../../hooks/terms/useTerms';
import useAutoOpenAddNote from './hooks/useAutoOpenAddNote';
import { useSearchParams } from 'react-router-dom';

type DataIndex = keyof EntityNotes;

export default function UseNotesContents(props?: Props) {
  const [paramsNotes, setParamsNotes] = useState<{
    page: number;
    start: number;
    limit: number;
    sort: string | undefined;
    filter: string | undefined;
  }>({
    page: 1,
    start: 0,
    limit: 20,
    sort: undefined,
    filter: undefined,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [uncheckedId, setUncheckedId] = useState<Array<number>>([]);
  const [dataSource, setDataSource] = useState<Array<EntityNotes>>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [dropDownFilterState, setDropDownFilterState] =
    useState<boolean>(false);
  const [filterData, setFilterData] = useState<NotesFilterType>({});
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const [selectedNotes, setSelectedNotes] = useState<EntityNotes | null>(null);
  const [exportN] = useExportNotesMutation();
  const [deleteNote, { isLoading: isLoadingDelete }] = useDeleteNoteMutation();
  const dispatch = useAppDispatch();
  const { options: notesOptions } = useTerms({
    types: [TermConst.noteStatus, TermConst.noteType],
  });
  const { txt } = useContext(ContextSegregated);

  useAutoOpenAddNote();

  const { data, isLoading, isSuccess } = useNotesQuery(paramsNotes);

  const debounceSearch = React.useCallback(
    debounce((txt) => {
      const params = getFilterParams();
      const tempFilter = [
        ...params,
        { property: 'filter', operator: 'like', value: `%${txt}%` },
      ];
      setParamsNotes({
        ...paramsNotes,
        page: 1,
        start: 0,
        limit: 20,
        filter: JSON.stringify(tempFilter),
      });
    }, 750),
    [],
  );

  const handleShowModalDelete = () => {
    setModalConfirmDelete(!modalConfirmDelete);
  };

  const onChangeFilterColumn = React.useCallback(
    (key: number | undefined) => {
      const found = uncheckedId?.find(
        (uncheckedTitle) => uncheckedTitle === key,
      );
      if (found) {
        setUncheckedId(uncheckedId.filter((t) => t !== key));
      } else {
        setUncheckedId([...uncheckedId, key || 0]);
      }
    },
    [uncheckedId],
  );

  const handleSorted = React.useCallback(
    (key: string, ruls: 'ASC' | 'DESC') => {
      const paramsFilter = JSON.stringify([{ property: key, direction: ruls }]);
      setParamsNotes({
        ...paramsNotes,
        sort: paramsFilter,
      });
    },
    [paramsNotes],
  );

  const editNotes = (id: number, data?: EntityNotes) => {
    const canOpen = isCan('write', Menu.note) || isCan('read', Menu.note);
    if (!canOpen) {
      return;
    }

    const foundNotes = data ?? dataSource?.find((el) => el.id === id);

    if (foundNotes) {
      searchParams.set('id', `${id}`);
      setSearchParams(searchParams);
      dispatch(
        updateModalAction({
          data: {
            ...foundNotes,
          },
          isAdd: true,
          type: 'notes_details',
        }),
      );
    }
  };

  const deleteNotes = (id: number, data?: EntityNotes) => {
    if (id) {
      handleShowModalDelete();
      const foundNote =
        data ??
        dataSource.find(
          (nts: EntityNotes) => nts.id?.toString() === id?.toString(),
        );
      setSelectedNotes(foundNote || null);
    }
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex | 'legalEntityId' | 'dealId',
  ): ColumnType<EntityNotes> => ({
    filterDropdown: ({ close }) =>
      FilterTab<EntityNotes>({
        columns: columns,
        dataIndex: dataIndex,
        handleSorted: (dataIndex: string, rule: 'ASC' | 'DESC') =>
          handleSorted(dataIndex, rule),
        onChangeFilterColumn: (key: number | undefined) =>
          onChangeFilterColumn(key),
        uncheckedId: uncheckedId,
        close: () => close(),
      }),
    filterIcon: () =>
      dropDownFilterState ? <ArrowDropdownIcon /> : <ArrowUpIcon />,
    onFilterDropdownOpenChange: (visible) => {
      setDropDownFilterState(visible);
    },
    render: (text: string) => <span>{text}</span>,
  });

  const handleColor = (value: string): string | undefined => {
    let classStyle = undefined;
    const isGray =
      value === 'settle' || value === 'draft' || value === 'net_off';
    const isBordered = value === 'sign_off' || value === 'raised';
    const isColored = value === 'past_due';
    if (isGray) {
      classStyle = classes.coloredGray;
    }
    if (isBordered) {
      classStyle = classes.coloredBorder;
    }
    if (isColored) {
      classStyle = classes.coloredRed;
    }
    return classStyle;
  };

  const columns: ColumnsType<EntityNotes> = React.useMemo(
    () =>
      [
        {
          title: 'ID',
          dataIndex: 'number',
          key: 1,
          ...getColumnSearchProps('number'),
          ellipsis: false,
          render: (text: string) => (
            <div className={classes.fixedMinHeaderId} title={text}>
              <span> {text}</span>
            </div>
          ),
        },

        {
          title: 'ISSUING DATE',
          dataIndex: 'issuing_date',
          key: 2,
          ellipsis: true,
          ...getColumnSearchProps('issuing_date'),
          render: (text: string) => {
            if (moment(text).isValid()) {
              return (
                <div className={classes.fixedMinHeaderDate}>
                  <span className=" px-2">
                    {text ? moment(text).format('DD/MM/YYYY') : ''}
                  </span>
                </div>
              );
            }
            return null;
          },
        },
        {
          title: 'PAYEMENT DATE',
          dataIndex: 'payment_date',
          key: 3,
          ellipsis: true,
          ...getColumnSearchProps('payment_date'),
          render: (text: string, record: any) => {
            if (moment(text).isValid()) {
              return (
                <div className={classes.fixedMinHeaderDate}>
                  <span className="">
                    {text && record.note_type !== 'ccni'
                      ? moment(text).format('DD/MM/YYYY')
                      : ''}
                  </span>
                </div>
              );
            }
            return null;
          },
        },

        {
          title: 'DEAL',
          dataIndex: 'deal',
          key: 13,
          ...getColumnSearchProps('note_type'),
          hidden: !props?.otherColumnsToAdd?.includes('deal'),
          render: (deal: EntityDeals, record: Partial<EntityNotes>) => {
            return deal?.number || record?.deal?.number || record?.deal_id;
          },
        },

        {
          title: 'SETTLEMENT DUE DATE',
          dataIndex: 'settlement_date',
          key: 4,
          className: classes.fixedMinHeaderDate,
          ...getColumnSearchProps('settlement_date'),
          ellipsis: true,
          render: (text: string, record: any) => {
            if (moment(text).isValid()) {
              return (
                <div className={classes.fixedMinHeaderDate}>
                  <span className="">
                    {text && record.note_type !== 'ccni'
                      ? moment(text).format('DD/MM/YYYY')
                      : ''}
                  </span>
                </div>
              );
            }
            return null;
          },
        },

        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 5,
          ...getColumnSearchProps('status'),
          render: (text: string) => {
            if (text) {
              return (
                <div className={classes.fixedMinHeaderStatus}>
                  <span className={handleColor(text)}>
                    {exportedDropdownValue(notesOptions, text)}
                  </span>
                </div>
              );
            }
          },
        },

        {
          title: 'RECIPIENT',
          dataIndex: 'legal_entity',
          key: 6,
          ...getColumnSearchProps('legal_entity_name'),
          ellipsis: true,
          render: (text: string, rec: Partial<EntityNotes>) => {
            if (text) {
              return (
                <div className={classes.fixedMinHeaderRecepient}>
                  <span title={rec?.legal_entity?.name}>
                    {rec?.legal_entity?.name}
                  </span>
                </div>
              );
            }
          },
        },

        {
          title: 'DEAL ID',
          dataIndex: 'deal_id',
          key: 7,
          className: classes.fixedMinHeaderStatus,
          ...getColumnSearchProps('dealId'),
          render: (text: string, record: Partial<EntityNotes>) => (
            <div>
              <span className=""> {record?.deal?.number}</span>
            </div>
          ),
        },

        {
          title: 'AMOUNT',
          dataIndex: 'amount',
          key: 8,
          ellipsis: false,
          ...getColumnSearchProps('amount'),
          render: (text: string, record: EntityNotes) => {
            return (
              <div
                className={classes.fixedMinHeaderAmount}
                style={{ textAlign: 'right' }}
              >
                <span className="">
                  {amountFormat(handleAmount(record).toFixed(2))}
                </span>
              </div>
            );
          },
        },

        {
          title: 'CURRENCY',
          dataIndex: 'currency',
          key: 9,
          ellipsis: true,
          className: classes.fixedMinHeaderCurrency,
          ...getColumnSearchProps('currency'),
          render: (text: string) => (
            <div>
              <span className=""> {text?.toUpperCase()}</span>
            </div>
          ),
        },
        {
          title: 'TYPE',
          dataIndex: 'note_type',
          key: 11,
          ...getColumnSearchProps('note_type'),
          hidden: !props?.otherColumnsToAdd?.includes('type'),
          render: (text: string) => {
            const label = exportedDropdownValue(notesOptions, text);
            return (
              <div>
                <span> {label}</span>
              </div>
            );
          },
        },
        {
          title: 'MANDATE NAME',
          dataIndex: 'mandate',
          key: 12,
          ...getColumnSearchProps('note_type'),
          hidden: !props?.otherColumnsToAdd?.includes('mandate'),
          render: (mandate: EntityMandate) => {
            return <span>{mandate.name}</span>;
          },
        },

        {
          title: '',
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          dataIndex: 'id',
          key: 10,
          width: 120,
          hidden: !isCan('write', Menu.note) && !isCan('delete', Menu.note),
          render: (text: number, record: EntityNotes) => {
            return (
              <>
                {isCan('write', Menu.note) && (
                  <span>
                    <i
                      className="rounded-icon"
                      onClick={() => editNotes(text, record)}
                    >
                      <Edit height="30" />
                    </i>
                  </span>
                )}
                {isCan('delete', Menu.note) && (
                  <span>
                    <i
                      className="rounded-icon"
                      onClick={() => deleteNotes(text, record)}
                    >
                      <Delete height="30" />
                    </i>
                  </span>
                )}
              </>
            );
          },
        },
      ]
        .filter((el) => !el.hidden)
        .filter(
          (el) => !props?.columnsToRemoved?.includes(el.dataIndex as DataIndex),
        ),
    [
      dataSource,
      dropDownFilterState,
      uncheckedId,
      props?.otherColumnsToAdd,
      props?.columnsToRemoved,
    ],
  );

  const columnsExport: Array<keyof NotesExport> = exportedNotes.map(
    (el) => el.itemId as keyof NotesExport,
  );

  const toggleFilterCollapse = () => {
    setFilterData({});
    const collapseSearch = document.getElementById('collapseSearch')!;
    const collapse = new bootstrap.Collapse(collapseSearch, { toggle: true });
    collapse.toggle();
  };

  const handleNotes = () => {
    dispatch(
      updateModalAction({
        data: null,
        isAdd: true,
        type: 'notes',
      }),
    );
  };

  const handleTableChange = React.useCallback(
    (current: number) => {
      setParamsNotes({
        ...paramsNotes,
        page: current,
      });
    },
    [paramsNotes],
  );

  const setPerPage = React.useCallback(
    (page: number) => {
      setParamsNotes({
        ...paramsNotes,
        limit: page,
      });
    },
    [paramsNotes],
  );

  const validDelete = async () => {
    if (selectedNotes?.id) {
      try {
        const res = await deleteNote({ id: selectedNotes.id }).unwrap();
        if (!res?.success) {
          dispatch(
            updateNotificationMessage({
              title: 'Unknown error',
              body: 'An rror occured while deleting the note',
              show: true,
            }),
          );
        }
      } catch (error: any) {
        const data = `${error?.data}`.toLowerCase();
        if (!data.includes('file')) {
          dispatch(
            updateNotificationMessage({
              title: 'Unknown error',
              body: 'An rror occured while deleting the note',
              show: true,
            }),
          );
        }
      } finally {
        handleShowModalDelete();
      }
    }
  };

  const getFilterParams = React.useCallback((): FilterType[] => {
    const values = Object.values(filterData).map((e) => {
      return {
        ...e,
        value: `${
          e.value && typeof e.value === 'string' ? e.value?.trim() : e.value
        }`,
      };
    });
    return values;
  }, [filterData]);

  const updateFilter = React.useCallback(
    (key: keyof NotesFilterType, tValue?: string | null) => {
      let newFilter = { ...filterData };
      const value = tValue?.toString();
      if (!value || value?.trim() === '') {
        delete newFilter[key];
      } else {
        newFilter = {
          ...newFilter,
          [key]: {
            property: key,
            operator: '=',
            value: value,
          },
        };
      }
      setFilterData(newFilter);
    },
    [filterData],
  );

  const getNotesCallback = () => {
    const params = getFilterParams();
    setParamsNotes({
      ...paramsNotes,
      page: 1,
      start: 0,
      limit: 20,
      filter: JSON.stringify(params),
    });
  };

  const lunchExportNotes = React.useCallback(
    async (fields: string[]) => {
      const res = await exportN({
        fields: fields,
        filter: Object.values(filterData),
      });
      if (res) {
        const rep = res as any;
        downloadCsv(rep.data, 'notes.csv');
      }
    },
    [filterData],
  );

  useEffect(() => {
    if (txt) {
      debounceSearch(txt);
    } else {
      setParamsNotes({
        page: 1,
        start: 0,
        limit: 20,
        sort: undefined,
        filter: undefined,
      });
    }
  }, [txt]);

  useEffect(() => {
    if (isSuccess) {
      setDataSource(data?.note || []);
      setTotalData(data.total);
    }
  }, [isSuccess, data?.note]);

  useEffect(() => {
    const params = getFilterParams();
    if (params.length === 0) {
      setParamsNotes({
        ...paramsNotes,
        page: 1,
        start: 0,
        limit: 20,
        filter: JSON.stringify(params),
      });
    }
  }, [filterData]);

  return {
    toggleFilterCollapse,
    dataSource,
    handleNotes,
    columns,
    uncheckedId,
    isLoading,
    paramsNotes,
    handleTableChange,
    totalData,
    setPerPage,
    modalConfirmDelete,
    handleShowModalDelete,
    validDelete,
    selectedNotes,
    isLoadingDelete,
    filterData,
    updateFilter,
    getNotesCallback,
    lunchExportNotes,
    columnsExport,
    editNotes,
  };
}

type Props = {
  otherColumnsToAdd?: string[];
  columnsToRemoved?: string[];
};
