import { SVGProps } from 'react';

const AddIcon = ({ color, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 2.75C17.1066 2.75 21.25 6.89338 21.25 12C21.25 17.1066 17.1066 21.25 12 21.25C6.89338 21.25 2.75 17.1066 2.75 12C2.75 6.89338 6.89338 2.75 12 2.75Z"
        fill="white"
        stroke={color || '#6D1206'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M16.5 12H7.49999H16.5Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.74951C12.4145 6.74951 12.7505 7.08551 12.7505 7.49999V11.2495H16.5C16.9145 11.2495 17.2505 11.5855 17.2505 12C17.2505 12.4145 16.9145 12.7505 16.5 12.7505H12.7505V16.5C12.7505 16.9145 12.4145 17.2505 12 17.2505C11.5855 17.2505 11.2495 16.9145 11.2495 16.5V12.7505H7.49999C7.08551 12.7505 6.74951 12.4145 6.74951 12C6.74951 11.5855 7.08551 11.2495 7.49999 11.2495H11.2495V7.49999C11.2495 7.08551 11.5855 6.74951 12 6.74951Z"
        fill={color || '#6D1206'}
      />
    </svg>
  );
};

export default AddIcon;
