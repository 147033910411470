import { Button, Modal, Container } from 'react-bootstrap';
import classes from './Modal.module.scss';
import LoadingIcon from '../../icons/LoadingIcon';

type Props = {
  show: boolean;
  title?: string;
  groupeName?: string;
  isLoadingDelete?: boolean;
  handleClose: () => void;
  validDelete: () => void;
  entityTitle?: string;
};

const DeleteModal = (props: Props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.handleClose()}
      className={classes.modalInfo}
      contentClassName={classes.modalContent}
      dialogClassName={classes.modalDialog}
      size="lg"
      centered
    >
      <form className="modal-confirm">
        <Modal.Header
          className={classes.modalHeader}
          closeVariant="white"
          closeButton
        >
          <Modal.Title>{props.title ?? 'Delete group'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="centered">
            <h1 className={classes.contentText}>
              Are you sure you wish to delete <br />
              {`"${props.groupeName ?? ''}" from ${
                props.entityTitle ? props.entityTitle : 'database '
              } ?`}
            </h1>
          </Container>
        </Modal.Body>
        <Modal.Footer className={classes.modalFooter}>
          <Button
            className="btn primary px-4 py-2 mx-2"
            onClick={() => props.handleClose()}
          >
            No
          </Button>
          <Button
            className={`btn primary active-link mx-2 ${
              !props.isLoadingDelete && 'px-4 py-2'
            }`}
            onClick={() => props.validDelete()}
          >
            {props.isLoadingDelete ? <LoadingIcon /> : 'Yes'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DeleteModal;
