import { ApiResponse, FilterType } from '..';
import { EntityDeals } from '../deals/deals';
import { LegalEntity } from '../legal-entities';

export type EntityNotes = {
  amount: number;
  attention_contact_id: number;
  broker_role: string | number;
  client_reference: string;
  commission_rate: number;
  endorsement_number: string | undefined;
  complete_ref: string;
  counterparty_id: number;
  create_user_id: number;
  created_at: string;
  currency: string;
  deal: EntityDeals;
  deal_id: number;
  deal_number: string;
  deleted_at: string;
  entity_bank_account_id: number;
  gross_rate: string;
  id: number;
  issuing_date: string;
  legal_entity: LegalEntity;
  legal_entity_id: number;
  mandate_id: number;
  net_rate: string;
  note_type: string;
  number: string;
  participation_id: number;
  payment_date: string;
  premium_calculation_basis: string;
  premium_type: string;
  segregated: number;
  settlement_date: string;
  status: string;
  tax_amount: number;
  umr_code: string;
  update_user_id: number;
  updated_at: string;
  note_comments: string;
  note_amount: string;
  legal_entity_name: string;
  attached_amount?: string;
  attached_currency?: string;
};

export type FilterNoteInput = {
  page?: number;
  start?: number;
  limit?: number;
  sort?: string;
  filter?: string;
  getLegalEntity?: number;
  getCompleteRef?: number;
  getDeal?: number;
};

export type NotesResponse = ApiResponse & {
  note: Array<EntityNotes>;
};

export type NotesFilterType = {
  note_type?: FilterType;
  status?: FilterType;
  counterparty_id?: FilterType;
};

export type NotesExport = {
  number?: any;
  note_type?: any;
  premium_type?: any;
  status?: any;
  mandate_id?: any;
  deal_id?: any;
  deal_number?: any;
  participation_id?: any;
  issuing_date?: any;
  settlement_date?: any;
  payment_date?: any;
  legal_entity_id?: any;
  group_id?: any;
  counterparty_id?: any;
  attention_contact_id?: any;
  broker_role?: any;
  currency?: any;
  amount?: any;
  tax_amount?: any;
  commission_rate?: any;
  gross_rate?: any;
  net_rate?: any;
  umr_code?: any;
  client_reference?: any;
  premium_calculation_basis?: any;
  created_at?: any;
  updated_at?: any;
};

export type InputFormsNotes = {
  amount: number | string;
  attention_contact_id: number;
  broker_role: number;
  client_reference: string;
  commission_rate: number | string;
  counterparty_id: number | undefined;
  currency: string;
  deal_id: number;
  deal_number: string;
  endorsement_number: string | undefined;
  entity_bank_account_id: number | null;
  gross_rate: number | string;
  id: number;
  issuing_date: string | undefined;
  legal_entity_id: number | undefined;
  net_rate: number | string;
  note_id: number | null;
  note_type: string;
  participation_id: number | null;
  payment_date: string | undefined;
  premium_calculation_basis: string;
  premium_type: string;
  segregated: boolean;
  settlement_date: string | undefined;
  status: string;
  tax_amount: number | string;
};

export type InputFormsNotesDetails = {
  amount: number | string;
  attention_contact_id: number;
  broker_role: number | string;
  client_reference: string;
  commission_rate: number | string;
  counterparty_id: number;
  currency: string;
  deal_id: number;
  deal_number: string;
  endorsement_number: string;
  entity_bank_account_id: number | null;
  gross_rate: number | string;
  id: number;
  issuing_date: string | undefined;
  legal_entity_id: number;
  net_rate: number | string;
  note_id: number | null;
  note_type: string;
  participation_id: number | null;
  payment_date: string | undefined;
  premium_calculation_basis: string;
  premium_type: string;
  segregated: boolean | undefined;
  settlement_date: string | undefined;
  status: string;
  tax_amount: number | string;
};

export type InputPayemenySegregated = {
  paymentId: number;
  amount: number | string;
  currency: string;
  comments: string;
};

export enum NoteTypes {
  cdnb = 'cdnb',
  pdni = 'pdni',
  cdnu = 'cdnu',
  pdnu = 'pdnu',
  ccnb = 'ccnb',
  ccni = 'ccni',
  cdni = 'cdni',
  pcni = 'pcni',
  pcnu = 'pcnu',
}

export const DebitNotes = [
  NoteTypes.pdni,
  NoteTypes.pdnu,
  NoteTypes.cdnb,
  NoteTypes.cdnu,
];

export type NoteAttachementType = 'credit' | 'net_off';

export interface NoteItem {
  key: number | string;
  id: number;
  noteId?: { key: any; value: any } | null;
  recipients?: string;
  noteAmount: string;
  currency: string;
  isCurrentEdit?: boolean;
  number?: string;
  complete_ref?: string;
  attached?: boolean;
}
