export const resourceEn = {
  nd_capacity: 'No Deal Capacity',
  nd_opporunity: 'No Deal Opportunity Gone',
  nd_other: 'No Deal Other',
  nd_pricing: 'No Deal Pricing',
  deal_risk_trigger_exists: 'Deal with the same risk trigger already exists !',
  enquiry_validation: 'Enquiry Validation',
  mandate_validation: 'Mandate Validation',
  invoice_raising: 'Invoice Raising',
  policy_validation: 'Policy Validation',
  kyc_vetting: 'KYC Vetting',
  download_document: 'Download Document',
  upload_document: 'Upload Document',
  document_download_error: 'An error occurred while downloading the document',
  document_upload_error: 'An error occurred while uploading the document',
  document_downloaded_successfully: 'Document downloaded successfully',
  document_uploaded_successfully: 'Document uploaded successfully',
  user_full_name: '{{firstName}} {{lastName}}',
  required: 'Required',
  kyc_required: 'KYC Informations required',
  toba_missing: 'TOBA informations missing',
  vetted: 'Vetted',
  expired: 'Expired',
  kyc_expired: 'KYC expired',
  legal_entity_missing: 'Legal Entity missing',
  information_notice: '{{group}} Information Notice',
  toba: 'TOBA',
  dispatch_date: 'Dispatch Date',
  signature_date: 'Signature Date',
  signed: 'Signed',
  rft: 'RFT',
  q_a: 'Q&A',
  credit_memo: 'Credit Memo',
  financial_information: 'Financial Information',
  insurance_policy: 'Insurance Policy',
  insured_contract: 'Insured Contract',
  other: 'Other',
  end_date: 'End Date',
  settlment_date: 'Settlement Date',
  insured_reporting_date: 'Insured Reporting Date',
  endorsment: 'Endorsement',
  insured_reporting: 'Insured Reporting',
  premium_invoicing: 'Premium Invoicing',
  claim: 'Claim',
  off_risk: 'Off-Risk',
  late_payment: 'Late Payment',
  contract_change: 'Contract Change',
  exposure: 'Exposure',
  currency: 'Currency',
  declaration_date: 'Declaration Date',
  date: 'Date',
  premium: 'Premium',
  deal_amount: 'Deal Amount',
  deal_currency: 'Deal Currency',
  expiry_date: 'Expiry date',
  close_of: 'Close of : signed by desk manager du deal owner',
  auhtor: 'Author',
  comment: 'Comment',
  comments: 'Comments',
  status: 'Status',
  type: 'Type',
  inception_date: 'Inception Date',
  risk_mode: 'Risk Mode',
  risk_trigger: 'Risk Trigger',
  run_off: 'Run Off',
  run_off_date: 'Run Off Date',
  minimum_deposit_premium: 'Minimum Deposit Premium',
  deal_loss: 'Loss risk',
  deal_risk: 'Risk attaching',
  ended: 'Ended',
  bound: 'Bound',
  binding: 'Binding',
  aborted: 'Aborted',
  yes: 'Yes',
  no: 'No',
  signed_off: 'Signed off',
  no_deal: 'No deal',
  no_deal_capacity: 'No deal capacity',
  no_deal_opporunity: 'No deal opportunity gone',
  deal: 'Deal',
  deal_capacity: 'Deal capacity',
};
