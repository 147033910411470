import { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { baseApi } from '../../../redux/api/baseApi';
import { useCurrencyExchangeRateQuery } from '../../../redux/api/ws/dashboard/dashboard';
import { useUploadAvatarMutation } from '../../../redux/api/ws/databases/cofarco-teams';
import { logout } from '../../../redux/slice/authSlice';
import { clearSession } from '../../../utils/user';
import { config } from '../../../data/config';

const useNavbar = () => {
  const dispatch = useAppDispatch();
  const [avatarKey, setAvatarKey] = useState<string | undefined>();
  const navigate = useNavigate();

  const disconnect = () => {
    clearSession();
    dispatch(logout());
    dispatch(baseApi.util.resetApiState());
    navigate('/login');
  };
  const authData = useAppSelector((state) => state.auth);
  const [avatarPath, setAvatarPath] = useState<string>(
    `${config.baseUrl}user/avatar/${authData?.user?.id}/${authData?.user?.avatar_filename}`,
  );

  const userName =
    authData?.user?.people?.firstname + ' ' + authData?.user?.people?.lastname;

  useEffect(() => {
    if (avatarKey) {
      setAvatarPath(avatarKey);
    }
  }, [avatarKey]);

  const [uploadAvatar] = useUploadAvatarMutation();

  const onUploadAvatar = (files: FileList | null) => {
    if (files && files.length > 0 && authData.user?.id) {
      uploadAvatar({ id: authData.user.id, data: files?.[0] }).then(
        (resp: any) => {
          if (resp && resp.data && resp.data.success) {
            setAvatarKey(
              `${config.baseUrl}user/avatar/${authData?.user?.id}/${resp.data.filename}`,
            );
          }
        },
      );
      return;
    }
  };

  const handleLogOut = () => {
    disconnect();
  };

  const handleChangePassword = (event: any) => {
    event?.domEvent?.preventDefault();
    setOpenChangePswd(true);
  };

  const [openChangePswd, setOpenChangePswd] = useState<boolean>(false);

  //GET CURRENCIES EXCHANGE
  const { data: dataCurrencies } = useCurrencyExchangeRateQuery({});

  const onChangeAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];

    if (selectedFile && selectedFile.type.startsWith('image/')) {
      onUploadAvatar(e.target.files);
    } else {
      e.target.files = null;
      e.target.value = '';
      console.error('Please select a valid image file.');
    }
  };

  return {
    avatarPath,
    userName,
    openChangePswd,
    dataCurrencies,
    authData,
    avatarKey,
    onChangeAvatar,
    handleChangePassword,
    handleLogOut,
    setOpenChangePswd,
  };
};

export default useNavbar;
