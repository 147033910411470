import { ColumnType, ColumnsType } from 'antd/es/table';
import { BusinessType, ConterParty } from '../../@types/conterParty';
import { useCallback, useMemo, useState } from 'react';
import { updateModalAction } from '../../redux/slice/modalSlice';
import { useAppDispatch } from '../redux';
import { useDeleteCounterPartyMutation } from '../../redux/api/ws/counterparties/get';
import { isCan, onHeaderCell } from '../../utils/helper-function';
import classes from '../../components/Dashboard/Dashboard.module.scss';
import { ReactComponent as Edit } from '../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../assets/img/Delete.svg';
import FilterTab from '../../utils/filter-tab';
import { LegalEntity } from '../../@types/legal-entities';
import { capitalize } from 'lodash';
import ArrowDropdownIcon from '../../icons/ArrowDropdownIcon';
import ArrowUpIcon from '../../icons/ArrowUpIcon';

const useCounterPartyresult = () => {
  const dispatch = useAppDispatch();

  const [deleteCounterParty, { isLoading }] = useDeleteCounterPartyMutation();

  const [showmodalDelete, setShowmodalDelete] = useState<boolean>(false);

  const [selectedCounterParty, setSelectedCounterParty] =
    useState<ConterParty | null>(null);

  const [dropDownFilterState, setDropDownFilterState] =
    useState<boolean>(false);
  const [uncheckedId, setUncheckedId] = useState<Array<number>>([]);

  const onChangeFilterColumn = (key: number | undefined) => {
    const found = uncheckedId?.find((uncheckedTitle) => uncheckedTitle === key);
    if (found) {
      setUncheckedId(uncheckedId.filter((t) => t !== key));
    } else {
      setUncheckedId([...uncheckedId, key || 0]);
    }
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): ColumnType<ConterParty> => ({
    filterDropdown: ({ close }) =>
      FilterTab<ConterParty>({
        columns: columns,
        dataIndex: dataIndex,
        handleSorted: () => {},
        onChangeFilterColumn: (key: number | undefined) =>
          onChangeFilterColumn(key),
        uncheckedId: uncheckedId,
        close: () => close(),
      }),
    filterIcon: () => {
      return dropDownFilterState ? (
        <ArrowDropdownIcon width={12} height={12} />
      ) : (
        <ArrowUpIcon width={12} height={12} />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      setDropDownFilterState(visible);
    },
    render: (text: string) => <span>{capitalize(text)}</span>,
  });

  const columns: ColumnsType<ConterParty> = useMemo(
    () => [
      {
        title: 'LEGAL ENTITY',
        key: 1,
        dataIndex: 'legal_entity',
        ...getColumnSearchProps('name'),
        render: (legal_entity: LegalEntity) => {
          return (
            <div
              style={{
                minWidth: '100px',
                maxWidth: '300px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <span>{legal_entity?.name ?? ''}</span>
            </div>
          );
        },
        onHeaderCell,
        width: '28%',
      },
      {
        title: 'COUNTERPARTY ID',
        dataIndex: 'name',
        key: 2,
        ...getColumnSearchProps('name'),
        render: (name: string) => {
          return (
            <div
              style={{
                minWidth: '100px',
                maxWidth: '300px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <span>{name.toUpperCase() ?? ''}</span>
            </div>
          );
        },
        width: '28%',
      },
      {
        title: 'COUNTERPARTY TYPE',
        dataIndex: 'business',
        key: 3,
        ...getColumnSearchProps('business'),
        onHeaderCell,
      },
      {
        title: 'TYPE',
        dataIndex: 'type',
        key: 4,
        ...getColumnSearchProps('type'),
        onHeaderCell,
        render: (type: string) => capitalize(type?.replaceAll('_', ' ')),
      },
      {
        title: '',
        dataIndex: 'action',
        className: classes.actionRow,
        onCell: () => {
          return {
            onClick: (event: any) => {
              event.stopPropagation();
            },
          };
        },
        render: (_: any, record: any) => (
          <div className="d-flex">
            {isCan('write', record.business) && (
              <span
                className={`mx-1 rounded-icon  ${classes.action}`}
                onClick={() => editCounterParty(record)}
              >
                <Edit height="30" />
              </span>
            )}
            {isCan('delete', record.business) && (
              <span
                className={`mx-1 rounded-icon ${classes.action}`}
                onClick={() => handleDelete(record)}
              >
                <Delete height="30" />
              </span>
            )}
          </div>
        ),
      },
    ],
    [uncheckedId, dropDownFilterState],
  );

  const editCounterParty = (record: ConterParty) => {
    dispatch(
      updateModalAction({
        data: record,
        isAdd: true,
        type: record.business,
      }),
    );
  };

  const deleteCounterPartyCallback = useCallback(() => {
    if (selectedCounterParty) {
      deleteCounterParty({
        id: selectedCounterParty.id,
        businessType: selectedCounterParty.business as BusinessType,
      }).then(() => {
        setShowmodalDelete(false);
      });
    }
  }, [selectedCounterParty, setShowmodalDelete]);

  const handleDelete = useCallback(
    (record: ConterParty) => {
      setSelectedCounterParty(record);
      setShowmodalDelete(true);
    },
    [setSelectedCounterParty, setShowmodalDelete],
  );

  return {
    columns,
    editCounterParty,
    deleteCounterPartyCallback,
    setShowmodalDelete,
    showmodalDelete,
    selectedCounterParty,
    setSelectedCounterParty,
    handleDelete,
    isLoadingDelete: isLoading,
    uncheckedId,
  };
};

export default useCounterPartyresult;

type DataIndex = keyof ConterParty;
