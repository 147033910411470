import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from './redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/scss/style.scss';
import './i18n';
import 'react-datepicker/dist/react-datepicker.css';

import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './components/AuthRequired/AuthProvider';
import { ConfigProvider } from 'antd';
import QuickProviderSearch from './ProviderQuickSearch';
import { getColor } from './utils/color';

const container = document.getElementById('root')!;
const root = createRoot(container);

const colorPrimary = getColor('--Rouge-bordeau');
const border = {
  borderRadius: 4,
  borderRadiusLG: 4,
  borderRadiusOuter: 0,
  borderRadiusSM: 4,
  borderRadiusXS: 4,
  boxShadow: 'none',
  controlOutline: 'none',
  controlOutlineWidth: 0,
  colorBorder: '#dee2e6',
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AuthProvider>
            <ConfigProvider
              theme={{
                components: {
                  Select: { ...border },
                  Input: { ...border },
                  Checkbox: { ...border, colorPrimary },
                  Button: {
                    colorPrimaryHover: colorPrimary,
                    defaultBg: '#dbdddf',
                  },
                },
                token: {
                  colorPrimary,
                  colorPrimaryHover: '#dee2e6',
                },
              }}
            >
              <QuickProviderSearch>
                <App />
              </QuickProviderSearch>
            </ConfigProvider>
          </AuthProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
reportWebVitals();
