import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { Menu, PermissionType } from '../@types/common-types';
import { EntityCountry } from '../@types/group/types';
import { Term } from '../@types/terms';
import { DropDownOptionObjectType } from '../components/common/DropDown/DropdownSelect';
import { store } from '../redux/store';
import toCamelCase from './toCamelCase';
import { range, startCase } from 'lodash';
import { EntityNotes, NoteTypes } from '../@types/segregatedaccounts/notes';
import { cofarcoFileTypes } from './data';

export const makeOptions = <T, U extends keyof T, V extends keyof T>(
  data: T[],
  value: U = 'value' as U,
  key: V = 'key' as V,
): { value: T[U]; key: T[V] }[] =>
  data &&
  data.map((el) => ({
    ...el,
    key: el[key],
    value: el[value],
  }));

export const concatString = (
  txt: string,
  txt2: string,
  space = ' ',
): string => {
  if (txt2) {
    return txt + space + ' ' + txt2;
  } else {
    return txt;
  }
};

export const handleRegion = (
  countries: Array<{ key: string; name: string }>,
  countryCode?: string,
): { name: string; code: string } | null => {
  if (countryCode) {
    const found = countries.find(
      (el) => el.key?.toLowerCase() === countryCode?.toLocaleLowerCase(),
    );
    if (found) {
      return { name: found.name, code: found.key };
    } else {
      return null;
    }
  }
  return null;
};

export const filterColumn = <T>(
  column: ColumnsType<T>,
  unselected: Array<number>,
) => {
  const selectedRow = column.filter((co: any) => {
    const isUnselected = unselected.find((uns: any) => uns === co.key);
    if (!isUnselected) {
      return co;
    }
  });
  return selectedRow;
};

export const isExist = (value: number | string | undefined | boolean | null) =>
  Boolean(
    value &&
      value !== 'false' &&
      value !== 0 &&
      value !== '0' &&
      value !== 'null' &&
      value !== null &&
      value !== 'undefined' &&
      value !== undefined,
  );

export const validURL = (str: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );
  return !!pattern.test(str);
};

export const exportOwnerShip = (
  txt: string | undefined,
  exported: 'label' | 'value' = 'value',
): string | undefined => {
  const ownerShipFilter = [
    {
      label: 'Administration',
      value: 'administration',
    },
    {
      label: 'Private',
      value: 'private',
    },
    {
      label: 'Public',
      value: 'public',
    },
    {
      label: 'State Owned',
      value: 'state_owned',
    },
  ];

  if (txt) {
    const selected = ownerShipFilter.find((el) => {
      if (exported === 'label') {
        return el.value === txt;
      } else {
        return el.label === txt;
      }
    });
    if (selected) {
      if (exported === 'label') {
        return selected.label;
      } else {
        return selected.value;
      }
    }
  }

  return undefined;
};

export const titleRequestError = 'Request Error';

export const exportedCountryKey = (countryes: EntityCountry[], key: string) => {
  const selected = countryes.find((el) => el.name == key);
  if (selected) {
    return selected.key;
  }
  return '';
};

export const exportedCountryName = (
  countryes: EntityCountry[],
  key: string | undefined,
) => {
  const selected = countryes.find((el) => el.key == key);
  if (selected) {
    return selected.name;
  }
  return '';
};

export const capitalizeFirstLetter = (
  string: string | undefined,
): string | undefined => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return undefined;
};

export const convertEquity = (equitySize: string): string => {
  if (equitySize && equitySize !== '') {
    const changeTxt = equitySize.replace('more', '>');
    const updatedSpace = changeTxt.replaceAll('_', ' ');
    const dynamicUnit = updatedSpace.includes('bn')
      ? updatedSpace
      : updatedSpace + 'm';
    return dynamicUnit + '$';
  } else {
    return '';
  }
};

export const exportDateTimeUi = (
  date: null | string | Date | undefined,
): Date | null | undefined | string => {
  if (date && moment(date, 'YYYY-MM-DD').isValid()) {
    return moment(date, 'YYYY-MM-DD HH:mm:ss')
      .format('DD/MM/YYYY HH:mm:ss')
      .valueOf();
  }
  return undefined;
};

// export const exportDateTimeWs = (date: string | Date | null | undefined) => {
//   if (date && moment(date, 'DD/MM/YYYY').isValid()) {
//     return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD').valueOf();
//   }
//   return null;
// };

export const exportDateUi = (
  date: null | string | Date | undefined,
): null | undefined | string => {
  if (date) {
    if (moment(date, 'YYYY-MM-DD').isValid()) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY').valueOf();
    } else if (moment(date).isValid()) {
      return moment(date).format('DD/MM/YYYY').valueOf();
    }
  }
  return undefined;
};

export const exportDateWs = (date: string | Date | null | undefined) => {
  if (date) {
    if (moment(date, 'DD/MM/YYYY').isValid()) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD').valueOf();
    } else if (moment(date).isValid()) {
      return moment(date).format('YYYY-MM-DD').valueOf();
    }
  }
  return null;
};

export const getUniqueArray = (data: Array<any>, exclude: Array<any>) => {
  const filtered = data.filter((el) => {
    if (!exclude.includes(el.key)) {
      return el;
    }
  });
  return filtered;
};

export const arrayColumn = <T>(arr: Array<T>, n: keyof T) =>
  arr.map((x) => x[n]);

export const transpose = (data: Array<any>) => {
  const result: any = {};
  for (const row of data) {
    for (const [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
};

export const isValidEmail = (email: string) => {
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const _isDate = (value: any) =>
  Object.prototype.toString.call(value) === '[object Date]';

export const _isNumber = (value: any) =>
  Object.prototype.toString.call(value) === '[object Number]';

export const _isObject = (value: any) =>
  Object.prototype.toString.call(value) === '[object Object]';

export const _isString = (value: any) =>
  Object.prototype.toString.call(value) === '[object String]';

export const _isArray = (value: any) => {
  return (
    value !== null &&
    Object.prototype.toString.call(value) === '[object Array]' &&
    value.length >= 0
  );
};

export const checkIsEdit = (data: number | string) => {
  return isExist(data) && data !== -1;
};

export const amountFormat = (x?: number | string, unit = 2) => {
  x = `${x || 0}`.replace(/'/g, '');
  const xNumber = parseFloat(Number(x).toFixed(unit));
  return xNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
};

export const exportedDropdownValue = (
  data: DropDownOptionObjectType[],
  key: string | number | null | undefined,
): string => {
  const selected = data?.find((el) => el.key?.toString() === key?.toString());

  if (selected) {
    return selected.value;
  }
  return '';
};

export const handleOnOff = (p: number) => {
  return p === 1 ? 'on' : 'off';
};

export const toBool = (bool_value: any) => (bool_value ? true : false);

export const convertObject = (obj: any) => {
  let ret = {};
  for (const property in obj) {
    let value;
    const isB =
      obj[property] === true ||
      obj[property] === false ||
      obj[property] === 0 ||
      obj[property] === 1;
    if (isB) {
      value = Boolean(obj[property]);
    }
    if (_isString(obj[property])) {
      value = isExist(obj[property]) ? obj[property] : undefined;
    }

    ret = {
      ...ret,
      [property]: value,
    };
  }
  return ret;
};

export const setDefaultValues = <T>(obj: any): T => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      switch (typeof obj[key as keyof T]) {
        case 'string':
          obj[key as keyof T] = null;
          break;
        case 'number':
          obj[key as keyof T] = 0;
          break;
        case 'boolean':
          obj[key as keyof T] = false;
          break;
        case 'object':
          if (Array.isArray(obj[key as keyof T])) {
            obj[key as keyof T] = [];
          } else {
            obj[key as keyof T] = '';
          }
          break;
        default:
          obj[key as keyof T] = undefined;
      }
    }
  }
  return obj;
};

export const isCan = (key: keyof PermissionType, menu: Menu) => {
  if (isAdmin()) return true;
  const state = store.getState().auth;
  const rigth = state.user?.rights || {};
  if (rigth[menu]) {
    return rigth[menu][key];
  }
  return false;
};

export const isAdmin = () => {
  const state = store.getState().auth;
  return state.user?.is_admin;
};

export const isCanReadMenu = (menu: Menu) => {
  if (isAdmin()) return true;
  const state = store.getState().auth;
  const rigth = state.user?.rights || {};
  if (rigth[menu]) {
    return rigth[menu].read;
  }
  return false;
};

export const isHaveAllAccessMenu = (
  objs: Array<{
    read: boolean;
    write: boolean;
    create: boolean;
    delete: boolean;
    extract: boolean;
  }>,
): boolean => {
  if (isAdmin()) return true;
  if (objs.every((p) => p !== undefined)) {
    return !!objs.find((p) => p.read);
  }
  return false;
};

export const handleRigthName = (name: string) => {
  const sing = ['people', 'enquiry', 'dashboard'];
  const makeEntity = sing.includes(name) ? name : name + 's';
  const updateUser = makeEntity === 'users' ? 'Cofarco Team' : makeEntity;
  const updateLegalEntity =
    updateUser === 'legal_entitys' ? 'Legal Entity' : updateUser;
  return capitalizeFirstLetter(updateLegalEntity);
};

export const handleConstant = (terms: Array<Term>, key: string) => {
  const found = terms.filter((el: Term) => el.type === key) || [];
  return found;
};

export const downloadCsv = (content: string, fileName: string) => {
  const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const cbNameExport = <T>(
  mockExport: Array<{ itemId: keyof T; text: string }>,
  txt: string,
): string => {
  const tempTxt = mockExport.find((el) => el.itemId === txt);
  return tempTxt?.text || toCamelCase(txt.toString(), 0, ' ');
};

export const initialiseValue = <T>(obj: any): T => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      switch (obj[key as keyof T]) {
        case null:
          obj[key as keyof T] = '';
          break;
        case undefined:
          obj[key as keyof T] = '';
          break;
        default:
          obj[key as keyof T] = obj[key];
      }
    }
  }
  return obj;
};

export const limitedText = (text: string, limit: number) => {
  return text && text.length > limit
    ? `${text && text.substring(0, limit)}...`
    : text;
};

export const nullingEmptyValues = <T>(obj: any): T => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      switch (obj[key as keyof T]) {
        case '':
          obj[key as keyof T] = undefined;
          break;
        case null:
          obj[key as keyof T] = undefined;
          break;
        case 0:
          obj[key as keyof T] = undefined;
          break;
        default:
          obj[key as keyof T] = obj[key];
      }
    }
  }
  return obj;
};

export const setEmptyUndefinedEditValues = <T>(obj: any): T => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      switch (obj[key as keyof T]) {
        case undefined:
          obj[key as keyof T] = '';
          break;
        default:
          obj[key as keyof T] = obj[key];
      }
    }
  }
  return obj;
};

export const exportUniqueId = (data: Array<any>): any => {
  const result = data?.reduce((accumulator: any, current: any) => {
    const exists = accumulator.find((item: any) => {
      return item.id === current.id;
    });
    if (!exists) {
      accumulator = accumulator.concat(current);
    }
    return accumulator;
  }, []);
  return result || [];
};

export const remplaceTxtValue = (
  txt: string,
  val: string,
  rule: string = '',
) => {
  if (txt) {
    return txt.replaceAll(val, rule);
  }
  return null;
};

export const isNumber = (txt: any) => {
  const pattern = /^\d+\.?\d*$/;
  return pattern.test(txt);
};

export const getObjectDifferences = (obj1: any, obj2: any) => {
  const diffObj: any = {};

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      if (
        val2 !== null &&
        typeof val1 === 'object' &&
        typeof val2 === 'object'
      ) {
        const nestedDiff = getObjectDifferences(val1, val2);
        if (Object.keys(nestedDiff).length > 0) {
          diffObj[key] = nestedDiff;
        }
      } else if (val2 !== val1) {
        diffObj[key] = val2;
      }
    }
  }
  return diffObj;
};

export const onHeaderCell = () => ({
  onClick: () => {},
  className: 'headerCustom',
});

export const floorNumber = (value: any): any => {
  if (value) {
    const floorV = value?.toString()?.replaceAll('%', '')?.replaceAll("'", '');
    const tempV = floorV?.toString();
    return Number(tempV?.trim()) || 0;
  }
  return 0;
};

export const initPercentage = (value: any) => {
  if (isExist(value)) {
    const floor = floorNumber(value);
    return '% ' + floor;
  }
  return undefined;
};

export const isAcceptedFile = (fileType: string): boolean => {
  if (fileType) {
    return cofarcoFileTypes.includes(fileType);
  }
  return false;
};

export const invalidTags = [
  'LIST_ENQUIRIES',
  'LIST_DEALS',
  'LIST_MANDATES',
  'ENQUIRY_LISTE_BY_MANDATE',
  'LIST_PIPES',
  'DEAL_LIST_BY_MANDATE',
  'GLOBAL_RESULT_SEARCH',
];

export const concatTitle = (v: string | null | undefined) =>
  v ? '- ' + v : '';

export const concatPercent = (
  v: string | null | undefined | number,
  prefixed: boolean = false,
) => (prefixed ? ' %' + v : v + ' %');

const getYear = (date: any) => {
  return date.getFullYear();
};

export const years = range(
  getYear(new Date()) - 14,
  getYear(new Date()) + 33,
  1,
);

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getMonth = (date: any) => {
  return date.getMonth();
};

export const addThousandSeparator = (value: any, separator: string) => {
  return (value ?? '').toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const isToday = (date: any) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const percentage = (partialValue: number, totalValue: number) => {
  return (partialValue * totalValue) / 100;
};

export const handleAmount = (_note: EntityNotes | null) => {
  if (!_note) return 0;
  const note = { ..._note };
  if (!note?.amount) note.amount = 0;
  if (!note?.tax_amount) note.tax_amount = 0;
  if (!note?.net_rate) note.net_rate = '0';
  if (!note?.commission_rate) note.commission_rate = 0;
  const { note_type, amount, commission_rate, tax_amount, net_rate } =
    note || {};
  let nbr = 0;

  switch (note_type) {
    case NoteTypes.pcnu:
    case NoteTypes.pdnu:
      nbr =
        amount -
        percentage(parseFloat(commission_rate?.toString()), amount as number) +
        parseFloat(tax_amount?.toString());
      break;

    case NoteTypes.pdni:
    case NoteTypes.pcni:
      nbr = parseFloat(amount?.toString()) + parseFloat(tax_amount?.toString());
      break;

    case NoteTypes.ccnb:
    case NoteTypes.cdnb:
      nbr =
        percentage(parseFloat(net_rate?.toString()), amount as number) +
        parseFloat(tax_amount?.toString());
      break;

    case NoteTypes.cdnu:
    case NoteTypes.ccni:
      nbr = parseFloat(amount?.toString()) + parseFloat(tax_amount?.toString());
      break;

    default:
      break;
  }
  return nbr;
};

export const formatTitleText = (text: string, limit: number = 30) => {
  let formattedText = '';
  while (text && text.length > limit) {
    const sub = text.substring(0, limit);
    let spaceIndex = sub.lastIndexOf(' ');
    if (spaceIndex === -1) spaceIndex = limit;
    formattedText += text.substring(0, spaceIndex) + '\n';
    text = text.substring(spaceIndex + 1);
  }
  formattedText += text;
  return formattedText;
};

export const transformcaseTextToText = (text: string) => {
  const transformedText = startCase(
    text
      .replace(/([A-Z])/g, ' $1')
      .replace(/_/g, ' ')
      .trim(),
  );
  return transformedText;
};

export const formateSize = (sizeInBytes: number) => {
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  let formattedSize = sizeInBytes;
  let unitIndex = 0;

  while (formattedSize >= 1024 && unitIndex < units.length - 1) {
    formattedSize /= 1024;
    unitIndex++;
  }

  return `${formattedSize.toFixed(2)} ${units[unitIndex]}`;
};

export const getDifferentKeys = (
  obj1: Record<string, any>,
  obj2: Record<string, any>,
): Record<string, boolean> => {
  const differentKeys: Record<string, boolean> = {};

  for (const key in obj1) {
    if (
      obj1.hasOwnProperty(key) &&
      obj2.hasOwnProperty(key) &&
      obj1[key] !== obj2[key]
    ) {
      differentKeys[key] = true;
    }
  }

  return differentKeys;
};
