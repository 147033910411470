import { lazy, Suspense } from 'react';
import { InputFormLegalEntity } from '../../@types/legal-entities';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { updateModalAction } from '../../redux/slice/modalSlice';
import { EnumDialog } from '../../@types/common-types';
import { Modal } from 'react-bootstrap';

const PeopleModal = lazy(() => import('../Database/People/Modal/PeopleModal'));
const ModalLegalEntity = lazy(
  () => import('../Database/LegalEntity/ModalLegalEntity/ModalLegalEntity'),
);
const IndicationModal = lazy(
  () => import('../Indication/Modal/IndicationModal/IndicationModal'),
);
const ClientModal = lazy(
  () => import('../CounterParties/Client/Modal/ClientModal'),
);
const UnderwriterModal = lazy(
  () => import('../CounterParties/Underwriter/Modal/UnderwriterModal'),
);
const ModalEnquiries = lazy(
  () => import('../Enquiries/ModalEnquiries/ModalEnquiries'),
);
const ModalGroup = lazy(
  () => import('../Database/Group/ModalGroup/ModalGroup'),
);
const FinancierModal = lazy(
  () => import('../CounterParties/Financier/Modal/FinancierModal'),
);
const PartnerModal = lazy(
  () => import('../CounterParties/Partner/Modal/PartnerModal'),
);
const ObligorModal = lazy(
  () => import('../CounterParties/Obligor/Modal/ObligorModal'),
);
const ModalMandate = lazy(
  () => import('../Mandates/ModalMandate/ModalMandate'),
);
const ModalDeals = lazy(() => import('../Deals/ModalDeals/ModalDeals'));
const ModalParticipations = lazy(
  () => import('../Participations/ModalParticipations/ModalParticipations'),
);
const ModalPayements = lazy(
  () => import('../SegregatedAccounts/Payements/ModalPayements/ModalPayements'),
);
const ModalNotes = lazy(
  () => import('../SegregatedAccounts/Notes/ModalNotes/ModalNotes'),
);
const ModalDetailNotes = lazy(
  () => import('../SegregatedAccounts/Notes/ModalDetailNotes/ModalDetailNotes'),
);
const FormCreateLimit = lazy(
  () =>
    import(
      '../Participations/ModalParticipations/FormCreateLimit/FormCreateLimit'
    ),
);
const FormCreateDealObligorLimit = lazy(
  () =>
    import(
      '../Deals/ModalDeals/LimitsList/FormCreateDealObligorLimit/FormCreateDealObligorLimit'
    ),
);
const ModalCofarcoTeamDetail = lazy(
  () => import('../CofarcoTeams/ModalCofarcoTeam/ModalCofarcoDetail'),
);
const ModalCofarcoTeam = lazy(
  () => import('../CofarcoTeams/ModalCofarcoTeam/ModalCofarcoTeam'),
);

export const AppDialogs = () => {
  const dispatch = useAppDispatch();

  /// Modal state
  const modal = useAppSelector((state) => state.modal);

  const getDefaultFormValues = (type: EnumDialog) => {
    switch (type) {
      case EnumDialog.legal_entity:
        return modal.currentModal?.data
          ? ({
              ...modal.currentModal?.data,
              group_name: modal.currentModal?.data?.group?.name,
            } as InputFormLegalEntity)
          : null;

      default:
        return modal.currentModal?.type === type
          ? modal.currentModal?.data
          : undefined;
    }
  };

  const handleClose = (type: EnumDialog) => {
    dispatch(
      updateModalAction({
        data: undefined,
        isAdd: false,
        type: type,
      }),
    );
  };

  const show = (type: EnumDialog) => {
    switch (type) {
      case EnumDialog.enquiry:
      case EnumDialog.indication:
      case EnumDialog.client:
      case EnumDialog.financier:
      case EnumDialog.partner:
      case EnumDialog.obligor:
      case EnumDialog.underwriter:
        return (modal.show && modal.currentModal?.type === type) ?? false;

      default:
        return (
          (modal.show &&
            modal.data.length > 0 &&
            modal.currentModal?.type === type) ??
          false
        );
    }
  };

  const getDialogByType = (type?: EnumDialog) => {
    if (!type) return null;
    const defaultFormValues = getDefaultFormValues(type);
    const commonProps = {
      show: show(type),
      handleClose: () => handleClose(type),
      onClose: () => handleClose(type),
    };
    switch (type) {
      case EnumDialog.group:
        return (
          <ModalGroup {...commonProps} defaultFormValues={defaultFormValues} />
        );
      case EnumDialog.enquiry:
        return (
          <ModalEnquiries
            {...commonProps}
            defaultFormValues={defaultFormValues}
          />
        );
      case EnumDialog.indication:
        return (
          <IndicationModal
            {...commonProps}
            defaultFormValues={defaultFormValues}
            onClickPeople={(p) => {
              dispatch(
                updateModalAction({ data: p, isAdd: true, type: 'people' }),
              );
            }}
          />
        );
      case EnumDialog.client:
        return (
          <ClientModal
            {...commonProps}
            defaultFormValues={defaultFormValues}
            onSubmittedForm={() => {
              dispatch(
                updateModalAction({
                  data: undefined,
                  type: EnumDialog.client,
                  isAdd: false,
                }),
              );
            }}
          />
        );
      case EnumDialog.financier:
        return (
          <FinancierModal
            {...commonProps}
            defaultFormValues={defaultFormValues}
            onSubmittedForm={() => {
              dispatch(
                updateModalAction({
                  data: undefined,
                  type: EnumDialog.financier,
                  isAdd: false,
                }),
              );
            }}
          />
        );
      case EnumDialog.underwriter:
        return (
          <UnderwriterModal
            {...commonProps}
            defaultFormValues={defaultFormValues}
            onSubmittedForm={() => {
              dispatch(
                updateModalAction({
                  data: undefined,
                  isAdd: false,
                  type: EnumDialog.underwriter,
                }),
              );
            }}
          />
        );
      case EnumDialog.partner:
        return (
          <PartnerModal
            {...commonProps}
            defaultFormValues={defaultFormValues}
            onSubmittedForm={() => {
              dispatch(
                updateModalAction({
                  data: undefined,
                  type: EnumDialog.partner,
                  isAdd: false,
                }),
              );
            }}
          />
        );
      case EnumDialog.obligor:
        return (
          <ObligorModal
            {...commonProps}
            defaultFormValues={defaultFormValues}
            top_obligor_country_id={
              modal.currentModal?.data?.top_obligor_country_id
            }
            onSubmittedForm={() => {
              dispatch(
                updateModalAction({
                  data: undefined,
                  type: EnumDialog.obligor,
                  isAdd: false,
                }),
              );
            }}
          />
        );
      case EnumDialog.people:
        return (
          <PeopleModal
            {...commonProps}
            defaultFormValues={defaultFormValues}
            dialogClassName="modal-90w"
            onSubmittedForm={() => {
              dispatch(
                updateModalAction({
                  data: undefined,
                  isAdd: false,
                  type: EnumDialog.people,
                }),
              );
            }}
          />
        );
      case EnumDialog.legal_entity:
        return (
          <ModalLegalEntity
            {...commonProps}
            defaultFormValues={defaultFormValues}
          />
        );
      case EnumDialog.mandate:
        return (
          <ModalMandate
            {...commonProps}
            defaultFormValues={defaultFormValues}
          />
        );
      case EnumDialog.cofarco_team:
        return (
          <ModalCofarcoTeamDetail
            {...commonProps}
            defaultFormValues={defaultFormValues}
          />
        );
      case EnumDialog.add_cofarco_team:
        return (
          <ModalCofarcoTeam
            {...commonProps}
            defaultFormValues={defaultFormValues}
          />
        );
      case EnumDialog.deals:
        return (
          <ModalDeals {...commonProps} defaultFormValues={defaultFormValues} />
        );
      case EnumDialog.participations:
        return (
          <ModalParticipations
            {...commonProps}
            defaultFormValues={defaultFormValues}
          />
        );
      case EnumDialog.payment:
        return (
          <ModalPayements
            {...commonProps}
            defaultFormValues={defaultFormValues}
          />
        );
      case EnumDialog.notes:
        return (
          <ModalNotes {...commonProps} defaultFormValues={defaultFormValues} />
        );
      case EnumDialog.notes_details:
        return (
          <ModalDetailNotes
            {...commonProps}
            defaultFormValues={defaultFormValues}
          />
        );
      case EnumDialog.limits:
        return (
          <FormCreateLimit
            {...commonProps}
            defaultFormValues={defaultFormValues}
          />
        );
      case EnumDialog.deal_obligor_limit:
        return (
          <FormCreateDealObligorLimit
            {...commonProps}
            defaultFormValues={defaultFormValues}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Suspense fallback={<Modal />}>
      {getDialogByType(modal.currentModal?.type as EnumDialog | undefined)}
    </Suspense>
  );
};
