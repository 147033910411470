import { Button, Modal } from 'react-bootstrap';
import classes from './ModalUnsaved.module.scss';
import { EntityCofarcoTeam } from '../../../@types/cofarco-teams/type';
import { useUpdateCofarcoPwdMutation } from '../../../redux/api/ws/databases/cofarco-teams';
import { User } from '../../../@types/common-types';
import { updateNotificationMessage } from '../../../redux/slice/notificationSlice';
import { useAppDispatch } from '../../../hooks/redux';
import { Input, Form } from 'antd';

const ModalChangePassword = ({
  showModalPassword,
  handleCancel,
  user,
}: Props) => {
  const [form] = Form.useForm<{
    password: string;
    password_confirmation: string;
  }>();

  const dispatch = useAppDispatch();

  const [updatePwd, { isLoading }] = useUpdateCofarcoPwdMutation();

  const handleClose = () => {
    handleCancel();
  };

  const handleValidate = async () => {
    form
      .validateFields()
      .then(async (data) => {
        try {
          if (!user?.id) return;
          await updatePwd({
            data: {
              ...data,
              people_id: user?.people_id,
              email: user?.email,
            },
            id: user?.id,
          }).then((res) => {
            if (
              res &&
              (
                res as {
                  data: { success: boolean; user: EntityCofarcoTeam };
                }
              ).data.success
            ) {
              handleClose();
              dispatch(
                updateNotificationMessage({
                  title: 'Modify password',
                  body: `Your password has been updated !`,
                  show: true,
                }),
              );
            }
          });
        } catch (error: any) {
          console.log(error);
        }
      })
      .catch(() => {});
  };

  return (
    <Modal
      show={showModalPassword}
      keyboard={false}
      scrollable={true}
      centered
      enforceFocus
      backdropClassName="modal-backdrop"
      id="modalUnsaved"
      onHide={() => handleClose()}
    >
      <Modal.Header>
        <Modal.Title>Modify Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form layout="vertical" form={form}>
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              { required: true, message: 'Password is required' },
              {
                min: 8,
                message: 'Password must have at least 8 characters',
              },
            ]}
          >
            <Input.Password size="large" placeholder="New Password" />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="password_confirmation"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password size="large" placeholder="Confirm Password" />
          </Form.Item>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <div className="d-inline px-2">
            <Button
              variant="default"
              onClick={() => handleClose()}
              className={`${classes.btnCancel}`}
            >
              Cancel
            </Button>
          </div>
          <div className="d-inline px-2">
            <Button
              disabled={isLoading}
              className={`${classes.btnSave}`}
              onClick={handleValidate}
            >
              Modify
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalChangePassword;

type Props = {
  dirtiFields?: any;
  showModalPassword: boolean;
  handleLeave: () => void;
  handleCancel: () => void;
  user: User | null;
};
