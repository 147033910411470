import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { ContentType, Method } from '../../../../@types/common-types';
import {
  BrokerItem,
  BrokerageResponse,
  DealsResponse,
  EntityAttachementDeals,
  EntityDeals,
  InputFormDeals,
} from '../../../../@types/deals/deals';
import { EntityMandate } from '../../../../@types/mandates/mandates';
import { datas } from '../../back-end-service';
import { baseApi } from '../../baseApi';
import { url } from '../../url';
import { invalidTags } from '../../../../utils/helper-function';
import {
  EntityLimits,
  EntityParticipation,
} from '../../../../@types/participation/participation';
import { InputFormLimits } from '../../../../@types/limit/types';
import { ConterPartyResponse } from '../../../../@types/conterParty';

const DealsEntity = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      deals: build.query<
        DealsResponse,
        {
          page: number | undefined;
          start: number | undefined;
          limit: number | undefined;
          sort: string;
          filter: string;
        }
      >({
        query: (queryParams) => {
          return {
            url: url.deals.list,
            params: {
              withWatchList: 1,
              getObligor: true,
              getClient: true,
              ...queryParams,
            },
          };
        },
        providesTags: ['LIST_DEALS'],
      }),

      exportDeals: build.mutation<string, { fields?: string[]; filter: any }>({
        query: (queryParams) => {
          return {
            url: url.deals.export,
            method: Method.POST,
            data: queryParams,
          };
        },
      }),

      exportDealsLimitObligor: build.mutation<
        string,
        { fields?: string[]; filter: any }
      >({
        query: (queryParams) => {
          return {
            url: url.limit.export,
            method: Method.POST,
            data: queryParams,
          };
        },
      }),

      deleteDeals: build.mutation<
        { success: boolean; legalentity: DealsResponse },
        { id: number }
      >({
        query: (body) => {
          return {
            url: url.deals.list + '/' + body.id,
            method: Method.DELETE,
          };
        },
        invalidatesTags: invalidTags.concat(['GLOBAL_RESULT_SEARCH']),
      }),

      getDealByMandateId: build.query<
        { deal: Array<EntityDeals>; success: string },
        {
          mandateId: string | number;
          enquiryId?: number | null;
          sort?: string;
          limit?: number;
          filter?: string;
        }
      >({
        query: (params) => {
          return {
            url: `${url.deals.list}`,
            params: {
              _dc: new Date().getTime(),
              ...params,
              page: 1,
              start: 0,
              limit: 100,
            },
          };
        },
        providesTags: ['DEAL_LIST_BY_MANDATE'],
      }),

      getMandateById: build.mutation<
        { mandate: EntityMandate; success: string },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.mandate.list}/${id}`,
            params: {
              _dc: new Date().getTime(),
              withClientAndName: true,
            },
          };
        },
      }),

      getDealsAttachement: build.mutation<
        { attachment: Array<EntityAttachementDeals>; success: string },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.deals.get_attachement}/${id}`,
            params: {
              _dc: new Date().getTime(),
            },
          };
        },
        // providesTags: ['ATTACHEMENT_DEALS'],
      }),

      createDeals: build.mutation<
        { success: boolean; deal: EntityDeals },
        InputFormDeals
      >({
        query: (body) => {
          return {
            url: url.deals.list,
            method: Method.POST,
            data: body,
          };
        },
        invalidatesTags: invalidTags,
      }),

      getParticipationByDealsId: build.query<
        { participation: EntityParticipation[]; success: string },
        { id: string | number; sort?: string }
      >({
        query: ({ id, sort }) => {
          return {
            url: `${url.participation.list}`,
            params: {
              _dc: new Date().getTime(),
              dealId: id,
              withUnderwriterEntity: true,
              sort,
              page: 1,
              start: 0,
              limit: 100,
            },
          };
        },
        providesTags: ['PARTICIPATION_BY_DEALS'],
      }),

      getLimitsByDealsId: build.query<
        { limit: any; success: string },
        { id: string | number; sort?: string }
      >({
        query: ({ id, sort }) => {
          return {
            url: `${url.limit.list}`,
            params: {
              _dc: new Date().getTime(),
              dealId: id,
              getType: 'deal_obligor',
              sort,
              page: 1,
              start: 0,
              limit: 100,
            },
          };
        },
        providesTags: ['LIMITS_BY_DEALS'],
      }),

      uploadDealsAttachement: build.mutation<
        { success: boolean; attachment: EntityAttachementDeals },
        { id: string | number; data: any }
      >({
        query: (body) => {
          return {
            url: url.deals.get_attachement + '/' + body.id,
            method: Method.POST,
            data: datas({ item: body.data }, 'multipart/form-data'),
          };
        },
      }),

      deleteDealAttachment: build.mutation<
        { success: boolean },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `attachment/${id}`,
            method: Method.DELETE,
          };
        },
        invalidatesTags: ['ATTACHEMENT_DEALS'],
      }),

      updateDeals: build.mutation<
        { success: boolean; deal: EntityDeals },
        InputFormDeals
      >({
        query: (body) => {
          return {
            url: url.deals.list + '/' + body.id,
            method: Method.PUT,
            data: body,
          };
        },
        invalidatesTags: [
          ...invalidTags,
          'PARTICIPATION_BY_DEALS',
          'LIMITS_BY_DEALS',
          'GLOBAL_RESULT_SEARCH',
          'DEAL_EVENTS',
        ],
      }),

      filterDealsByNotes: build.mutation<
        { success: boolean; deal: Array<EntityDeals> },
        {
          query?: string;
          limit?: number;
          filter?: string;
          notStatus?: string[];
        }
      >({
        query: ({ ...params }) => {
          return {
            url: url.deals.list,
            method: Method.GET,
            params: {
              _dc: new Date().getTime(),
              withUnderwriterEntity: true,
              notStatus: ['binding', 'aborted'],
              withClientAndObligor: true,
              page: 1,
              start: 0,
              limit: 200,
              ...params,
            },
          };
        },
      }),

      updateDealObligorLimit: build.mutation<
        {
          limit: EntityLimits;
          success: boolean;
        },
        { form: InputFormLimits }
      >({
        query: (queryParams) => {
          return {
            url: url.limit.edit + '/' + queryParams.form.id,
            method: Method.PUT,
            params: {
              getType: queryParams?.form?.type,
              dealId: queryParams.form?.deal_id,
            },
            data: queryParams.form,
          };
        },
        invalidatesTags: ['LIMITS_BY_DEALS'],
      }),

      searchCounterparty: build.mutation<
        ConterPartyResponse,
        { query: any; page?: number }
      >({
        query: (body) => {
          const { query, page } = body;
          return {
            url: url.counterparty.list,
            params: {
              query,
              businessType: 'partner',
              page: page || 1,
              start: 0,
              limit: 25,
            },
            method: Method.GET,
          };
        },
      }),

      getBrokerage: build.mutation<ConterPartyResponse, { dealId: number }>({
        query: (body) => {
          const { dealId } = body;
          return {
            url: url.counterparty.list,
            params: {
              getLegalEntity: true,
              getCompleteRef: true,
              businessType: 'partner',
              dealId,
              page: 1,
              start: 0,
              limit: 100,
              query: '%%',
            },
            method: Method.GET,
          };
        },
      }),

      postBrokerage: build.mutation<
        BrokerageResponse,
        { brokers: Array<BrokerItem>; id: number }
      >({
        query: (credentials) => ({
          url: `${url.deals.list}/${credentials?.id}/brokers`,
          method: Method.POST,
          data: datas(
            {
              brokers: JSON.stringify(credentials?.brokers),
            },
            ContentType.FORM_DATA,
          ),
        }),
      }),

      updateAttachementByDealsId: build.mutation<
        { form: any; success: string },
        {
          id: number;
          dealId: number;
          comments?: string;
          attachment_type?: string;
        }
      >({
        query: ({ id, dealId, comments, attachment_type }) => {
          return {
            url: `${url.deals.get_attachement}/${dealId}/${id}`,
            method: Method.PUT,
            data: {
              attachment_type,
              comments,
              id: id,
            },
          };
        },
        invalidatesTags: ['ATTACHEMENT_DEALS'],
      }),
    };
  },
});

export const {
  useDealsQuery,
  useLazyDealsQuery,
  useDeleteDealsMutation,
  useExportDealsMutation,
  useLazyGetDealByMandateIdQuery,
  useGetDealByMandateIdQuery,
  useGetMandateByIdMutation,
  useCreateDealsMutation,
  useLazyGetParticipationByDealsIdQuery,
  useGetParticipationByDealsIdQuery,
  useLazyGetLimitsByDealsIdQuery,
  // useLazyGetDealsAttachementQuery,
  useGetDealsAttachementMutation,
  useUploadDealsAttachementMutation,
  useDeleteDealAttachmentMutation,
  useUpdateDealsMutation,
  useFilterDealsByNotesMutation,
  useUpdateDealObligorLimitMutation,
  useSearchCounterpartyMutation,
  useGetBrokerageMutation,
  usePostBrokerageMutation,
  useUpdateAttachementByDealsIdMutation,
  useExportDealsLimitObligorMutation,
} = DealsEntity;
