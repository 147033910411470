import { resourceEn } from './en';
import { resourceFr } from './fr';

export const languageResource = {
  en: {
    translation: resourceEn,
  },
  fr: {
    translation: resourceFr,
  },
};
