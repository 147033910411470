import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { baseApi } from '../../baseApi';
import { url } from '../../url';
import { ApiFilter, ApiPagination } from '../../../../@types';
import {
  BusinessType,
  ConterParty,
  ConterPartyResponse,
} from '../../../../@types/conterParty';
import { Method } from '../../../../@types/common-types';

const counterPartyGet = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      listCounterParty: build.query<
        ConterPartyResponse,
        ApiPagination &
          ApiFilter & {
            businessType?: BusinessType;
            businessTypes?: string;
            query?: string;
          }
      >({
        query(params) {
          return {
            url: `${url.counterparty.list}`,
            params: {
              ...params,
              getLegalEntity: true,
              getGroup: true,
              getLegalEntityCountry: true,
              _dc: new Date().getTime(),
            },
          };
        },
        providesTags: (result) =>
          result
            ? result.counterparty.map((e) => ({
                type: 'CounterParty',
                id: e.id,
              }))
            : ['CounterParty'],
      }),
      deleteCounterParty: build.mutation<
        { success: boolean },
        { id: number; businessType: BusinessType }
      >({
        query: ({ id, businessType }) => {
          return {
            url: `${url.counterparty.list}/${id}`,
            params: { _dc: new Date().getTime(), businessType },
            method: Method.DELETE,
          };
        },
        invalidatesTags: () => ['CounterParty', 'GLOBAL_RESULT_SEARCH'],
      }),

      getCounterPartyById: build.query<
        { counterparty: ConterParty; success: string },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.counterparty.list}/${id}`,
            params: {
              _dc: new Date().getTime(),
              getLegalEntity: true,
              getGroup: true,
              // enquiryId: id,
              // getUnderwriter: true,
              // getContact: true,
              page: 1,
              start: 0,
              limit: 25,
            },
          };
        },
        providesTags: ['ITEM_COUNTERPARTY'],
      }),
    };
  },
});

export const {
  useLazyListCounterPartyQuery,
  useListCounterPartyQuery,
  useDeleteCounterPartyMutation,
  useLazyGetCounterPartyByIdQuery,
  useGetCounterPartyByIdQuery,
} = counterPartyGet;
