import { useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLazyDealsQuery } from '../../../../redux/api/ws/deals/deals';
import { useAppDispatch } from '../../../../hooks/redux';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import { EnumDialog } from '../../../../@types/common-types';
import { isNaN } from 'lodash';

const useAutoOpenAddNote = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [getDeal] = useLazyDealsQuery();
  const hasOpened = useRef(false);

  const open = useCallback(async (dealId: number, noteTypes: string[]) => {
    if (hasOpened.current) return; // Empêche d'ouvrir 2 fois
    hasOpened.current = true;
    const search = {
      limit: 1,
      filter: `[{"property":"id","value":${dealId},"operator":"="}]`,
      page: 1,
      start: 0,
      sort: '',
    };
    const { data: _dData } = await getDeal(search);
    if (_dData?.deal?.length === 1) {
      dispatch(
        updateModalAction({
          data: {
            deal: _dData.deal.at(0),
            note_types: noteTypes,
          },
          isAdd: true,
          type: EnumDialog.notes,
        }),
      );
      setSearchParams(new URLSearchParams());
    }
  }, []);

  useEffect(() => {
    const dealId = searchParams.get('deal_id');
    const noteTypes = searchParams.get('note_types') ?? '';
    if (dealId && !isNaN(dealId)) {
      open(parseInt(dealId), noteTypes.split(','));
    }
  }, [searchParams]);
};

export default useAutoOpenAddNote;
