import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { ContentType, Method } from '../../../../@types/common-types';
import {
  InputPayemenySegregated,
  NotesResponse,
} from '../../../../@types/segregatedaccounts/notes';
import {
  BankAccountResponse,
  BankResponse,
  EntityPayements,
  InputFormBankAccount,
  InputFormPayement,
  InputNoteSegregated,
  PayementResponse,
} from '../../../../@types/segregatedaccounts/payements';
import { datas } from '../../back-end-service';

import { baseApi } from '../../baseApi';
import { url } from '../../url';
import { TFilterDeals } from '../../../../@types/deals/deals';

const payements = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      payements: build.query<PayementResponse, TFilterDeals>({
        query: (queryParams) => {
          return {
            url: url.segregatedAccounts.payement,
            params: queryParams,
          };
        },
        providesTags: ['LIST_PAYEMENT'],
      }),

      getPaymentById: build.query<
        PayementResponse,
        {
          id: number;
        }
      >({
        query: (queryParams) => {
          const { id } = queryParams;
          return {
            url: url.segregatedAccounts.payement + '/' + id,
            params: {
              getLegalEntity: true,
              getCompleteRef: true,
              segregated: 1,
            },
          };
        },
      }),

      deletePayement: build.mutation<{ success: boolean }, { id: number }>({
        query: (body) => {
          return {
            url: url.segregatedAccounts.payement + '/' + body.id,
            method: Method.DELETE,
            params: {
              getLegalEntity: true,
              getBankAccountOwner: true,
              segregated: 1,
            },
          };
        },
        invalidatesTags: ['LIST_PAYEMENT', 'GLOBAL_RESULT_SEARCH'],
      }),

      getBanks: build.mutation<BankResponse, { query: any }>({
        query: (body) => {
          const { query } = body;
          return {
            url: url.segregatedAccounts.bank_account,
            params: {
              query,
              getOwner: true,
              segregated: 1,
              page: 1,
              start: 0,
              limit: 25,
              sort: [
                { property: 'bank', direction: 'ASC' },
                { property: 'currency', direction: 'ASC' },
                { property: 'info', direction: 'ASC' },
              ],
            },
            method: Method.GET,
          };
        },
      }),

      getNotesByPayementId: build.mutation<NotesResponse, { id: number }>({
        query: (body) => {
          const { id } = body;
          return {
            url: url.segregatedAccounts.notes,
            params: {
              getLegalEntity: true,
              getCompleteRef: true,
              getDeal: true,
              paymentId: id,
              page: 1,
              start: 0,
              limit: 100,
            },
            method: Method.GET,
          };
        },
      }),

      exportPayement: build.mutation<
        string,
        { fields?: string[]; filter: any }
      >({
        query: (queryParams) => {
          return {
            url: url.segregatedAccounts.exportPayement,
            method: Method.POST,
            data: queryParams,
          };
        },
      }),

      getBanksAccount: build.query<
        BankResponse,
        {
          getOwner?: boolean;
          segregated?: number;
          legalEntityId?: number;
          page: number;
          start: number;
          limit: number;
          sort?: Array<{ property: string; direction: string }>;
        }
      >({
        query: (queryParams) => {
          const {
            start,
            page,
            limit,
            sort,
            segregated,
            getOwner,
            legalEntityId,
          } = queryParams;
          return {
            url: url.segregatedAccounts.bank_account,
            params: {
              start,
              page,
              limit,
              sort,
              segregated,
              getOwner,
              legalEntityId,
            },
          };
        },
      }),

      filteredNotes: build.mutation<
        NotesResponse,
        {
          query: string | undefined;
          params?: Record<string, any>;
        }
      >({
        query: (queryParams) => {
          console.log('Filtered Note');
          const { query, params } = queryParams;
          return {
            url: url.segregatedAccounts.notes,
            method: Method.GET,
            params: {
              getLegalEntity: true,
              getCompleteRef: true,
              getDeal: true,
              status: 'raised,past_due',
              page: 1,
              start: 0,
              limit: 100,
              query,
              ...(params ?? {}),
            },
          };
        },
        // providesTags: ['FILTERED_NOTES_ATTACHED_LIST'],
      }),

      postNotes: build.mutation<
        NotesResponse,
        { notes: Array<InputNoteSegregated>; id: number }
      >({
        query: (credentials) => ({
          url: `${url.segregatedAccounts.payement}/${credentials?.id}/notes`,
          method: Method.POST,
          data: datas(
            {
              notes: JSON.stringify(credentials?.notes),
            },
            ContentType.FORM_DATA,
          ),
        }),
        invalidatesTags: ['LIST_PAYEMENT'],
      }),

      createPayement: build.mutation<
        { success: boolean; payement: InputFormPayement },
        EntityPayements
      >({
        query: (body) => {
          return {
            url: url.segregatedAccounts.payement,
            method: Method.POST,
            data: body,
          };
        },
        invalidatesTags: ['LIST_PAYEMENT'],
      }),

      updatePayement: build.mutation<
        { success: boolean; payement: InputFormPayement },
        EntityPayements
      >({
        query: (body) => {
          return {
            url: url.segregatedAccounts.payement + '/' + body.id,
            method: Method.PUT,
            data: body,
            params: {
              getLegalEntity: true,
              getBankAccountOwner: true,
              segregated: 1,
            },
          };
        },
        invalidatesTags: ['LIST_PAYEMENT', 'GLOBAL_RESULT_SEARCH'],
      }),

      postNotesInPayement: build.mutation<
        PayementResponse,
        { payements: Array<InputPayemenySegregated>; id: number }
      >({
        query: (credentials) => ({
          url: `${url.segregatedAccounts.notes}/${credentials?.id}/notes`,
          method: Method.POST,
          data: datas(
            {
              notes: JSON.stringify(credentials?.payements),
            },
            ContentType.FORM_DATA,
          ),
        }),
      }),
      postAddIban: build.mutation<
        { success: boolean; bank: BankAccountResponse },
        InputFormBankAccount
      >({
        query: (body) => {
          return {
            url: url.segregatedAccounts.bank_account,
            method: Method.POST,
            data: body,
          };
        },
      }),
    };
  },
});
export const {
  usePayementsQuery,
  useLazyGetPaymentByIdQuery,
  useDeletePayementMutation,
  useGetBanksMutation,
  useExportPayementMutation,
  useLazyGetBanksAccountQuery,
  useFilteredNotesMutation,
  usePostNotesMutation,
  useCreatePayementMutation,
  useGetNotesByPayementIdMutation,
  useUpdatePayementMutation,
  usePostNotesInPayementMutation,
  usePostAddIbanMutation,
} = payements;
