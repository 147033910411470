import React, { useState } from 'react';

import { ReactComponent as Edit } from '../../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../../assets/img/Delete.svg';
import FilterTab from '../../../utils/filter-tab';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import {
  capitalizeFirstLetter,
  convertEquity,
  exportOwnerShip,
  handleRegion,
  isCan,
  onHeaderCell,
} from '../../../utils/helper-function';

import FlagItem from '../../../components/FlagItem/FlagItem';
import { EntityGroupe } from '../../../@types/group/types';
import { Menu } from '../../../@types/common-types';
import { useGetCountryQuery } from '../../../redux/api/ws/databases/groups';
import { EntityCountry } from '../../../@types/group/types';
import ArrowDropdownIcon from '../../../icons/ArrowDropdownIcon';
import ArrowUpIcon from '../../../icons/ArrowUpIcon';

type DataIndex = keyof EntityGroupe;

type IProps = {
  handleSorted: (key: string, ruls: 'ASC' | 'DESC') => void;
  editGroup: (idGroupe: number, data?: EntityGroupe) => Promise<void>;
  deleteGroup: (id: number, data?: EntityGroupe) => void;
  groupes: Array<EntityGroupe>;
};
export default function ColumnsGroupContent(props: IProps) {
  const [dropDownFilterState, setDropDownFilterState] =
    useState<boolean>(false);
  const [uncheckedId, setUncheckedId] = useState<Array<number>>([]);

  const onChangeFilterColumn = (key: number | undefined) => {
    const found = uncheckedId?.find((uncheckedTitle) => uncheckedTitle === key);
    if (found) {
      setUncheckedId(uncheckedId.filter((t) => t !== key));
    } else {
      setUncheckedId([...uncheckedId, key || 0]);
    }
  };

  const { data: responseCountry } = useGetCountryQuery();

  const countries: Array<EntityCountry> = responseCountry?.country || [];

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): ColumnType<EntityGroupe> => ({
    filterDropdown: ({ close }) =>
      FilterTab<EntityGroupe>({
        columns: columns,
        dataIndex: dataIndex,
        handleSorted: (dataIndex: string, rule: 'ASC' | 'DESC') =>
          props.handleSorted(dataIndex, rule),
        onChangeFilterColumn: (key: number | undefined) =>
          onChangeFilterColumn(key),
        uncheckedId: uncheckedId,
        close: () => close(),
      }),
    filterIcon: () => {
      return dropDownFilterState ? (
        <ArrowDropdownIcon width={12} height={12} />
      ) : (
        <ArrowUpIcon width={12} height={12} />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      setDropDownFilterState(visible);
    },
    render: (text: string) => <span>{text}</span>,
  });

  const columns: ColumnsType<EntityGroupe> = React.useMemo(
    () =>
      [
        {
          title: 'NAME',
          dataIndex: 'name',
          key: 1,
          ...getColumnSearchProps('name'),
          render: (text: string) => <span> {text}</span>,
          onHeaderCell,
          width: '30%',
        },

        {
          title: 'OWNERSHIP',
          dataIndex: 'ownership',
          key: 2,
          ...getColumnSearchProps('ownership'),
          render: (text: string) => {
            if (text) {
              return (
                <span className="border-base">
                  {capitalizeFirstLetter(exportOwnerShip(text, 'label'))}
                </span>
              );
            }
          },
          onHeaderCell,
          width: '20%',
        },
        {
          title: 'EQUITY SIZE',
          dataIndex: 'equity_size',
          key: 3,
          ...getColumnSearchProps('equity_size'),
          render: (text: string) => {
            if (text) {
              return <span className="border-gray">{convertEquity(text)}</span>;
            }
          },
          onHeaderCell,
          width: '20%',
        },
        {
          title: 'COUNTRY',
          dataIndex: 'origin_country_id',
          key: 4,
          ...getColumnSearchProps('origin_country_name'),
          render: (text: string) => {
            const region = handleRegion(countries, text);
            return (
              region && (
                <FlagItem code={region.code} region_name={region?.name} />
              )
            );
          },
          onHeaderCell,
          width: '20%',
        },
        {
          title: '',
          dataIndex: 'id',
          key: 5,
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          width: '10%',
          hidden: !isCan('write', Menu.group) && !isCan('delete', Menu.group),
          render: (text: number, record: EntityGroupe) => {
            return (
              <>
                {isCan('write', Menu.group) && (
                  <span>
                    <i
                      className="rounded-icon"
                      onClick={() => props.editGroup(text, record)}
                    >
                      <Edit height="30" />
                    </i>
                  </span>
                )}

                {isCan('delete', Menu.group) && (
                  <span>
                    <i
                      className="rounded-icon"
                      onClick={() => props.deleteGroup(text, record)}
                    >
                      <Delete height="30" />
                    </i>
                  </span>
                )}
              </>
            );
          },
        },
      ].filter((el) => !el.hidden),
    [props.groupes, uncheckedId, dropDownFilterState],
  );

  return { columns, uncheckedId };
}
