import { debounce } from 'lodash';
import { useState, useCallback } from 'react';
import { FilterType } from '../../@types';
import { DealsFilterType } from '../../@types/deals/deals';
import { useDealsQuery } from '../../redux/api/ws/deals/deals';

// type UseFilterDealsType = {
//   excludes?: number[];
// };

export const useFilterDeals = () => {
  const [filterData, setFilterData] = useState<DealsFilterType>({
    team: {
      value: ['ldn', 'par', 'gva'],
      operator: 'in',
      property: 'team',
    },
  });

  const [paramsDeals, setParamsDeals] = useState<{
    page: number | undefined;
    start: number | undefined;
    limit: number | undefined;
    sort: string;
    filter: string;
  }>({
    page: 1,
    start: 0,
    limit: 20,
    sort: JSON.stringify([{ property: 'number', direction: 'DESC' }]),
    filter: '[]',
  });
  const { data, isLoading, isSuccess } = useDealsQuery(paramsDeals);

  const getFilterParams = useCallback((): FilterType[] => {
    const values = Object.values(filterData).map((e) => {
      if (e.property === 'team') {
        return {
          ...e,
          value: e.value,
        };
      }
      return {
        ...e,
        value: `${typeof e.value === 'string' ? e.value?.trim() : e.value}`,
      };
    });
    return values;
  }, [filterData]);

  const debounceSearch = useCallback(
    debounce((txt) => {
      const params = getFilterParams();
      const tempFilter = [
        ...params,
        { property: 'filter', operator: 'like', value: `%${txt}%` },
      ];
      // if (excludes) {
      //   tempFilter.push({
      //     property: 'id',
      //     operator: 'not in',
      //     value: excludes,
      //   });
      // }
      setParamsDeals({
        ...paramsDeals,
        page: 1,
        start: 0,
        limit: 20,
        filter: JSON.stringify(tempFilter),
      });
    }, 750),
    [],
  );

  return {
    paramsDeals,
    setFilterData,
    setParamsDeals,
    debounceSearch,
    filterData,
    data,
    isLoading,
    isSuccess,
  };
};
