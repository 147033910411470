import { Avatar, AvatarProps } from 'antd';
import { EntityCofarcoTeam } from '../../../@types/cofarco-teams/type';
import { config } from '../../../data/config';
import { getNameAvatarText } from '../../../utils/user';

type Props = AvatarProps & {
  user?: Partial<EntityCofarcoTeam> | null;
};

const UserAvatar = ({ user, ...props }: Props) => {
  return (
    <Avatar
      style={{
        backgroundColor: user?.is_inactif === 1 ? '' : 'var(--Rouge-bordeau)',
      }}
      src={`${config.baseUrl}user/avatar/${user?.id}/${user?.avatar_filename}`}
      size={40}
      {...props}
    >
      {getNameAvatarText(user)}
    </Avatar>
  );
};

export default UserAvatar;
