import React, { useState } from 'react';

import { EntityMandate, MandateExport } from '../../@types/mandates/mandates';
import { ReactComponent as Edit } from '../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../assets/img/Delete.svg';
import FilterTab from '../../utils/filter-tab';
import type { ColumnType } from 'antd/es/table';
import {
  capitalizeFirstLetter,
  exportedDropdownValue,
  handleOnOff,
  isCan,
  limitedText,
} from '../../utils/helper-function';
import classes from './Mandate.module.scss';
import { Menu, TermConst } from '../../@types/common-types';
import UseConstante from '../../hooks/use-constante';
import { exportedMandates } from '../../utils/exported-mandates';
import ArrowDropdownIcon from '../../icons/ArrowDropdownIcon';
import ArrowUpIcon from '../../icons/ArrowUpIcon';
type DataIndex = keyof EntityMandate;

type IProps = {
  handleSorted: (key: string, ruls: 'ASC' | 'DESC') => void;
  editMandate: (id: number, data?: EntityMandate) => void;
  handleDeleteMandate: (id: number, data?: EntityMandate) => void;
  mandate: Array<EntityMandate>;
};
export default function ColumnsMandate(props: IProps) {
  const [dropDownFilterState, setDropDownFilterState] =
    useState<boolean>(false);
  const [uncheckedId, setUncheckedId] = useState<Array<number>>([]);
  const terms = UseConstante();
  const onChangeFilterColumn = (key: number | undefined) => {
    const found = uncheckedId?.find((uncheckedTitle) => uncheckedTitle === key);
    if (found) {
      setUncheckedId(uncheckedId.filter((t) => t !== key));
    } else {
      setUncheckedId([...uncheckedId, key || 0]);
    }
  };

  const columnsExport: Array<keyof MandateExport> = exportedMandates.map(
    (el) => el.itemId as keyof MandateExport,
  );

  const getColumnSearchProps = (
    dataIndex: DataIndex | 'clientId',
  ): ColumnType<EntityMandate> => ({
    filterDropdown: ({ close }) =>
      FilterTab<EntityMandate>({
        columns: columns,
        dataIndex: dataIndex,
        handleSorted: (dataIndex: string, rule: 'ASC' | 'DESC') =>
          props.handleSorted(dataIndex, rule),
        onChangeFilterColumn: (key: number | undefined) =>
          onChangeFilterColumn(key),
        uncheckedId: uncheckedId,
        close: () => close(),
      }),
    align: 'left',

    filterIcon: () => {
      return dropDownFilterState ? (
        <ArrowDropdownIcon width={12} height={12} />
      ) : (
        <ArrowUpIcon width={12} height={12} />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      setDropDownFilterState(visible);
    },
    render: (text: string) => <span>{text}</span>,
  });

  /*const getOwnerName = (id: number | undefined) => {
    const found = ownerList?.user.find((el: EntityOwnerName) => el.id === id);
    if (found) {
      return found.fullname;
    }
    return null;
  };*/

  const onHeaderCell = () => ({
    onClick: () => {},
    className: classes.headerCustom,
  });

  const columns: any = React.useMemo(
    () =>
      [
        {
          title: 'ID',
          dataIndex: 'number',
          key: 1,
          ...getColumnSearchProps('number'),
          className: classes.fixedSizeHeaderSm,
          render: (text: string) => (
            <div
              style={{
                minWidth: '70px',
                maxWidth: '70px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <span> {text}</span>
            </div>
          ),
        },

        {
          title: 'ACTIVITY',
          dataIndex: 'activity',
          className: classes.fixedSizeHeaderSm,
          key: 2,
          ...getColumnSearchProps('activity'),
          render: (text: number) => (
            <div
              style={{
                minWidth: '50px',
                maxWidth: '50px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <span className={`${text === 1 ? classes.one : classes.off}`}>
                <b>{handleOnOff(text)}</b>
              </span>
            </div>
          ),
        },
        {
          title: 'PHASE',
          className: classes.fixedSizeHeaderMd,
          dataIndex: 'phase',
          key: 3,
          ...getColumnSearchProps('phase'),
          onHeaderCell,
          render: (text: string, rec: Partial<EntityMandate>) => {
            if (text) {
              return (
                <div
                  style={{
                    minWidth: '90px',
                    maxWidth: '90px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <span
                    className={`${
                      rec.activity === 1 && text === 'lead'
                        ? classes.phaseColoredLead
                        : rec.activity === 1 && text === 'offer'
                          ? classes.phaseColoredOffert
                          : rec.activity === 1
                            ? classes.phaseColored
                            : classes.phase
                    }`}
                  >
                    <b>{capitalizeFirstLetter(text)}</b>
                  </span>
                </div>
              );
            }
          },
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 4,
          ...getColumnSearchProps('status'),
          className: classes.fixedSizeHeaderStatus,

          render: (text: string, rec: Partial<EntityMandate>) => {
            if (text) {
              return (
                <div
                  style={{
                    minWidth: '140px',
                    maxWidth: '140px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <span
                    className={`status`}
                    style={{
                      borderColor: rec.activity === 1 ? '#EB5D57' : '#A5A9A8',
                      color: rec.activity === 1 ? '#EB5D57' : '#A5A9A8',
                    }}
                  >
                    <b>
                      {exportedDropdownValue(
                        terms?.getConst(TermConst.mandateStatus) || [],
                        text,
                      )}
                    </b>
                  </span>
                </div>
              );
            }
          },
        },
        {
          title: 'CLIENT',
          dataIndex: 'client',
          key: 5,
          ...getColumnSearchProps('clientId'),
          className: classes.fixedSizeHeaderClient,
          render: (text: number, rec: Partial<EntityMandate>) => {
            if (rec.client?.name) {
              return (
                <div
                  style={{
                    minWidth: '130px',
                    maxWidth: '130px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <span>
                    <b>
                      {capitalizeFirstLetter(limitedText(rec.client?.name, 25))}
                    </b>
                  </span>
                </div>
              );
            }
          },
        },
        {
          title: 'MANDATE NAME',
          dataIndex: 'name',
          key: 6,
          className: classes.fixedSizeHeaderMandateName,
          ...getColumnSearchProps('name'),
          render: (text: string) => {
            if (text) {
              return (
                <div
                  style={{
                    maxWidth: '250px',
                    minWidth: '250px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <span>
                    <b>{capitalizeFirstLetter(text)}</b>
                  </span>
                </div>
              );
            }
          },
        },
        {
          title: 'TYPE',
          dataIndex: 'mandate_type',
          className: classes.fixedSizeHeaderType,
          key: 7,
          ...getColumnSearchProps('mandate_type'),
          render: (text: string) => {
            if (text) {
              return (
                <div
                  style={{
                    minWidth: '150px',
                    maxWidth: '150px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <span className={`gray-rounded`}>
                    <b>
                      {exportedDropdownValue(
                        terms?.getConst(TermConst.mandateType) || [],
                        text,
                      )}
                    </b>
                  </span>
                </div>
              );
            }
          },
        },
        /*{
          title: 'TEAM',
          dataIndex: 'in_charge_team',
          key: 8,
          className: classes.fixedSizeHeaderTeam,
          ...getColumnSearchProps('in_charge_team'),
          render: (text: string, rec: Partial<EntityMandate>) => {
            console.log('rec in_charge_team', rec , text);
            
            if (text) {
              return (
                <div
                  style={{
                    minWidth: '60px',
                    maxWidth: '60px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <span className={`${classes.gray}`}>
                    <b>
                      {exportedDropdownValue(
                        terms?.getConst(TermConst.staffTeam),
                        text
                      )}
                    </b>
                  </span>
                </div>
              );
            }
          },
        },*/
        {
          title: 'OWNER',
          dataIndex: 'owner_name',
          key: 9,
          className: classes.fixedSizeHeaderOwner,

          ...getColumnSearchProps('owner_name'),
          render: (text: string) => {
            return (
              <div
                style={{
                  minWidth: '150px',
                  maxWidth: '150px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                <span>{text}</span>
              </div>
            );
          },
        },
        {
          title: '',
          dataIndex: 'id',
          key: 10,
          width: 120,
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          hidden:
            !isCan('write', Menu.mandate) && !isCan('delete', Menu.mandate),
          render: (text: number, record: EntityMandate) => {
            return (
              <>
                {isCan('write', Menu.mandate) && (
                  <span style={{ minWidth: 95 }}>
                    <i
                      className="rounded-icon"
                      onClick={() => props.editMandate(text, record)}
                    >
                      <Edit height="30" />
                    </i>
                  </span>
                )}
                {isCan('delete', Menu.mandate) && (
                  <span style={{ minWidth: 55 }}>
                    <i
                      className="rounded-icon"
                      onClick={() => props.handleDeleteMandate(text, record)}
                    >
                      <Delete height="30" />
                    </i>
                  </span>
                )}
              </>
            );
          },
        },
      ].filter((el) => !el.hidden),
    [props.mandate, dropDownFilterState, uncheckedId, terms],
  );

  return { columns, uncheckedId, columnsExport };
}
