import { ColumnType } from 'antd/es/table';
import React, { useEffect, useState, useContext } from 'react';
import FilterTab from '../../../utils/filter-tab';
import { ReactComponent as Edit } from '../../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../../assets/img/Delete.svg';
import * as bootstrap from 'bootstrap';
import moment from 'moment';
import {
  amountFormat,
  capitalizeFirstLetter,
  downloadCsv,
  isCan,
} from '../../../utils/helper-function';
import {
  useDeletePayementMutation,
  useExportPayementMutation,
  usePayementsQuery,
} from '../../../redux/api/ws/segregatedaccounts/payement';
import {
  EntityPayements,
  PayementExport,
  PayementsFilterType,
} from '../../../@types/segregatedaccounts/payements';
import { FilterType } from '../../../@types';
import { exportedPayement } from '../../../utils/exported-payement';
import classes from './Payement.module.scss';
import { Menu } from '../../../@types/common-types';
import { updateNotificationMessage } from '../../../redux/slice/notificationSlice';
import { useAppDispatch } from '../../../hooks/redux';
import { updateModalAction } from '../../../redux/slice/modalSlice';
import { ContextSegregated } from '../SegregatedContent';
import { debounce } from 'lodash';
import ArrowDropdownIcon from '../../../icons/ArrowDropdownIcon';
import ArrowUpIcon from '../../../icons/ArrowUpIcon';
import { TFilterDeals } from '../../../@types/deals/deals';

type DataIndex = keyof EntityPayements;

export default function UsePayementsContents(props?: Props) {
  const defaultData = {
    page: 1,
    start: 0,
    limit: 20,
    getLegalEntity: true,
    getBankAccountOwner: true,
    getDeal: true,
    segregated: 1,
  };
  const [paramsPayement, setParamsPayement] = useState<TFilterDeals>({
    ...defaultData,
  });
  const [uncheckedId, setUncheckedId] = useState<Array<number>>([]);
  const [dropDownFilterState, setDropDownFilterState] =
    useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const [selectedPayement, setSelectedPayement] =
    useState<EntityPayements | null>(null);
  const [filterData, setFilterData] = useState<PayementsFilterType>({});

  const { data, isLoading } = usePayementsQuery(paramsPayement);
  const [exportP] = useExportPayementMutation();
  const dispatch = useAppDispatch();
  const dataSource = data?.payment || [];
  const totalData = data?.total || 0;

  const [
    deletePayement,
    {
      isLoading: isLoadingDelete,
      isSuccess: isSuccessDelete,
      isError: isErrorDelete,
    },
  ] = useDeletePayementMutation();

  const { txt } = useContext(ContextSegregated);

  const debounceSearch = React.useCallback(
    debounce((txt) => {
      const params = getFilterParams();
      const tempFilter = [
        ...params,
        { property: 'filter', operator: 'like', value: `%${txt}%` },
      ];
      setParamsPayement({
        ...paramsPayement,
        page: 1,
        start: 0,
        limit: 20,
        filter: JSON.stringify(tempFilter),
      });
    }, 750),
    [],
  );

  useEffect(() => {
    if (txt) {
      debounceSearch(txt);
    } else {
      setParamsPayement({
        ...defaultData,
      });
    }
  }, [txt]);

  const onChangeFilterColumn = React.useCallback(
    (key: number | undefined) => {
      const found = uncheckedId?.find(
        (uncheckedTitle) => uncheckedTitle === key,
      );
      if (found) {
        setUncheckedId(uncheckedId.filter((t) => t !== key));
      } else {
        setUncheckedId([...uncheckedId, key || 0]);
      }
    },
    [uncheckedId],
  );

  const handleSorted = React.useCallback(
    (key: string, ruls: 'ASC' | 'DESC') => {
      const paramsFilter = JSON.stringify([{ property: key, direction: ruls }]);
      setParamsPayement({
        ...paramsPayement,
        sort: paramsFilter,
      });
    },
    [paramsPayement],
  );

  const handleEditPayement = (id: number, data?: EntityPayements) => {
    const findP = data ?? dataSource?.find((el) => el.id === id);

    if (findP) {
      dispatch(
        updateModalAction({
          data: findP,
          isAdd: true,
          type: 'payements',
        }),
      );
    }
  };

  const handleDeletePayement = (id: number, payment?: EntityPayements) => {
    if (id) {
      handleShowModalDelete();
      const foundPayement =
        payment ??
        data?.payment.find(
          (gr: EntityPayements) => gr.id?.toString() === id?.toString(),
        );
      setSelectedPayement(foundPayement || null);
    }
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex | 'legalEntityId',
  ): ColumnType<EntityPayements> => ({
    filterDropdown: ({ close }) =>
      FilterTab<EntityPayements>({
        columns: columns,
        dataIndex: dataIndex,
        handleSorted: (dataIndex: string, rule: 'ASC' | 'DESC') =>
          handleSorted(dataIndex, rule),
        onChangeFilterColumn: (key: number | undefined) =>
          onChangeFilterColumn(key),
        uncheckedId: uncheckedId,
        close: () => close(),
      }),
    filterIcon: () =>
      dropDownFilterState ? <ArrowDropdownIcon /> : <ArrowUpIcon />,
    onFilterDropdownOpenChange: (visible) => {
      setDropDownFilterState(visible);
    },
    render: (text: string) => <span>{text}</span>,
  });

  const handleStatus = (value: string): string | undefined => {
    let classStyle = undefined;
    const isGray = value === 'assigned';
    const isBordered = value === 'unassigned';
    if (isGray) {
      classStyle = classes.coloredGray;
    }
    if (isBordered) {
      classStyle = classes.coloredBorder;
    }

    return classStyle;
  };

  const onHeaderCell = () => ({
    onClick: () => {},
    className: 'headerCustom',
  });

  const columns: any = React.useMemo(
    () =>
      [
        {
          title: 'ID',
          dataIndex: 'number',
          key: 1,
          ...getColumnSearchProps('number'),
          onHeaderCell,
          render: (text: string) => <b> {text}</b>,
        },
        {
          title: 'DEBIT/CREDIT',
          dataIndex: 'direction',
          key: 2,
          ...getColumnSearchProps('direction'),
          onHeaderCell,
          render: (text: string) => {
            if (text) {
              return <b className={classes.coloredGray}>{text}</b>;
            }
          },
        },
        {
          title: 'COFARCO ACCOUNT',
          dataIndex: 'owner_bank_account',
          key: 3,
          ...getColumnSearchProps('owner_bank_account'),
          render: (text: string, record: EntityPayements) => (
            <div
              style={{ maxWidth: 250 }}
              className={classes.ellipsis}
              title={text}
            >
              <b> {text ?? record.legal_entity.name}</b>
            </div>
          ),
        },
        {
          title: 'LEGAL ENTITY',
          dataIndex: 'legal_entity',
          className: 'contentHeaderCustom',
          key: 4,
          ...getColumnSearchProps('legal_entity_name'),
          onHeaderCell,
          render: (text: string, rec: Partial<EntityPayements>) => (
            <div
              style={{ maxWidth: 150 }}
              className={classes.ellipsis}
              title={text}
            >
              <b>{rec.legal_entity?.name}</b>
            </div>
          ),
        },
        {
          title: 'Deal ID',
          dataIndex: 'deal_number',
          key: 5,
          ...getColumnSearchProps('deal_number'),
          onHeaderCell,
          render: (dealNumber?: string) => <b> {dealNumber ?? ''}</b>,
        },
        {
          title: 'PAYEMENT DATE ',
          dataIndex: 'payment_date',
          key: 6,
          ...getColumnSearchProps('payment_date'),
          onHeaderCell,
          render: (text: string) => {
            if (moment(text).isValid()) {
              return <b>{moment(text).format('DD/MM/YYYY')}</b>;
            }
            return null;
          },
        },
        {
          title: 'AMOUNT',
          dataIndex: 'amount',
          key: 7,
          ...getColumnSearchProps('amount'),
          onHeaderCell,
          with: 110,
          render: (text: string) => (
            <div
              style={{
                maxWidth: 110,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                textAlign: 'right',
              }}
            >
              <b> {amountFormat(text)}</b>
            </div>
          ),
        },
        {
          title: 'CURRENCY',
          dataIndex: 'currency',
          key: 8,
          ...getColumnSearchProps('currency'),
          onHeaderCell,
          render: (text: string) => <b> {text?.toUpperCase()}</b>,
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 9,
          ...getColumnSearchProps('status'),
          onHeaderCell,
          render: (text: string) => {
            if (text) {
              return (
                <div
                  style={{
                    minWidth: '100px',
                    maxWidth: '100px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <b className={handleStatus(text)}>
                    {capitalizeFirstLetter(text)}
                  </b>
                </div>
              );
            }
          },
        },
        {
          title: '',
          dataIndex: 'id',
          key: 10,
          width: 120,
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          hidden:
            !isCan('write', Menu.payment) && !isCan('delete', Menu.payment),
          render: (text: number, record: EntityPayements) => {
            return (
              <>
                {isCan('write', Menu.payment) && (
                  <span style={{ minWidth: 95 }}>
                    <i
                      className="rounded-icon"
                      onClick={() => handleEditPayement(text, record)}
                    >
                      <Edit height="30" />
                    </i>
                  </span>
                )}
                {isCan('delete', Menu.payment) && (
                  <span style={{ minWidth: 55 }}>
                    <i
                      className="rounded-icon"
                      onClick={() => handleDeletePayement(text, record)}
                    >
                      <Delete height="30" />
                    </i>
                  </span>
                )}
              </>
            );
          },
        },
      ]
        .filter((el) => !el.hidden)
        .filter(
          (el) => !props?.columnsToRemoved?.includes(el.dataIndex as DataIndex),
        ),
    [
      data,
      dropDownFilterState,
      uncheckedId,
      props?.otherColumnsToAdd,
      props?.columnsToRemoved,
    ],
  );

  const toggleFilterCollapse = () => {
    setFilterData({});
    const collapseSearch = document.getElementById('collapseSearch')!;
    const collapse = new bootstrap.Collapse(collapseSearch, { toggle: true });
    collapse.toggle();
  };

  // useEffect(() => {
  //   if (isSuccess) {
  //     setDataSource(data?.payment || []);
  //     setTotalData(data.total);
  //   }
  // }, [isSuccess, data]);

  const handleModal = () => {
    setShow(!show);
  };

  const handlePayement = () => {
    dispatch(
      updateModalAction({
        data: null,
        isAdd: true,
        type: 'payements',
      }),
    );
  };

  const handleTableChange = React.useCallback(
    (current: number) => {
      setParamsPayement({
        ...paramsPayement,
        page: current,
      });
    },
    [paramsPayement],
  );

  const setPerPage = React.useCallback(
    (page: number) => {
      setParamsPayement({
        ...paramsPayement,
        limit: page,
      });
    },
    [paramsPayement],
  );

  const handleShowModalDelete = () => {
    setModalConfirmDelete(!modalConfirmDelete);
  };

  const validDelete = () => {
    if (selectedPayement?.id) {
      deletePayement({ id: selectedPayement?.id || -1 });
    }
  };

  useEffect(() => {
    if (isSuccessDelete) {
      handleShowModalDelete();
    }
  }, [isSuccessDelete]);

  React.useEffect(() => {
    if (isErrorDelete) {
      handleShowModalDelete();
      dispatch(
        updateNotificationMessage({
          title: 'Server error',
          body: `The server has encountered an internal error`,
          show: true,
        }),
      );
    }
  }, [isErrorDelete]);

  const getFilterParams = React.useCallback((): FilterType[] => {
    const values = Object.values(filterData).map((e) => {
      return {
        ...e,
        value: `${
          e.value && typeof e.value === 'string' ? e.value?.trim() : e.value
        }`,
      };
    });
    return values;
  }, [filterData]);

  const updateFilter = React.useCallback(
    (key: keyof PayementsFilterType, tValue?: string | null) => {
      let newFilter = { ...filterData };
      const value = tValue?.toString();
      if (!value || value?.trim() === '') {
        delete newFilter[key];
      } else {
        newFilter = {
          ...newFilter,
          [key]: {
            property: key,
            value: value,
            operator: '=',
          },
        };
      }
      setFilterData(newFilter);
    },
    [filterData],
  );

  const getPayementsCallback = () => {
    const params = getFilterParams();
    setParamsPayement({
      ...paramsPayement,
      page: 1,
      start: 0,
      limit: 20,
      filter: JSON.stringify(params),
    });
  };

  useEffect(() => {
    const params = getFilterParams();
    if (params.length === 0) {
      setParamsPayement({
        ...paramsPayement,
        page: 1,
        start: 0,
        limit: 20,
        filter: JSON.stringify(params),
      });
    }
  }, [filterData, getFilterParams]);

  const columnsExport: Array<keyof PayementExport> = exportedPayement.map(
    (el) => el.itemId as keyof PayementExport,
  );

  const lunchExportPayement = React.useCallback(
    async (fields: string[]) => {
      const res = await exportP({
        fields: fields,
        filter: Object.values(filterData),
      });
      if (res) {
        const rep = res as any;
        downloadCsv(rep.data, 'payments.csv');
      }
    },
    [filterData],
  );

  return {
    toggleFilterCollapse,
    dataSource,
    handlePayement,
    columns,
    uncheckedId,
    isLoading,
    paramsPayement,
    handleTableChange,
    totalData,
    setPerPage,
    handleShowModalDelete,
    modalConfirmDelete,
    validDelete,
    selectedPayement,
    isLoadingDelete,
    filterData,
    updateFilter,
    getFilterParams,
    getPayementsCallback,
    columnsExport,
    lunchExportPayement,
    show,
    handleModal,
    handleEditPayement,
  };
}

type Props = {
  otherColumnsToAdd?: string[];
  columnsToRemoved?: string[];
};
