import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ModalData = {
  isAdd: boolean;
  data: any;
  type: string;
};

export interface ModalState {
  data: ModalData[];
  show?: boolean;
  currentModal?: ModalData;
}

const initialState: ModalState = {
  data: [],
  show: false,
  currentModal: undefined,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    updateModalAction: (state, action: PayloadAction<ModalData>) => {
      if (action.payload.isAdd) {
        state = {
          ...state,
          currentModal: action.payload,
          show: true,
          data: [...state.data, action.payload],
        };
      } else {
        if (state.data.length <= 1) {
          state = {
            show: false,
            data: [],
            currentModal: undefined,
          };
        } else {
          const array = [...state.data];
          array.pop();
          state = {
            ...state,
            show: true,
            currentModal: array.slice(-1)[0],
            data: array,
          };
        }
      }
      return state;
    },
    closeAllModal: (state) => {
      state = {
        ...initialState,
      };
      return state;
    },
    updateModalData: (state, action: PayloadAction<ModalData>) => {
      return {
        ...state,
        currentModal: action.payload,
        data: state.data.map((d) => {
          if (d.data.id === action.payload.data.id) {
            return action.payload;
          }
          return d;
        }),
        type: action.payload.type,
      };
    },
  },
});

export const { updateModalAction, closeAllModal, updateModalData } =
  modalSlice.actions;
export default modalSlice.reducer;
