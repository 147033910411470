import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { saveAs } from 'file-saver';
import Filters from '../../assets/img/filter.png';
import ExportDropdown from '../ExportDropdown/ExportDropdown';
import { useExportIndicationMutation } from '../../redux/api/ws/indication/post';
import { useAppSelector } from '../../hooks/redux';
import { FilterType } from '../../@types';
import { isCan } from '../../utils/helper-function';
import { Menu } from '../../@types/common-types';
import AddIcon from '../../icons/AddIcon';

const ExportObject = {
  number: 'ID',
  enquiry_number: 'Enquiry ID',
  client_id: 'Client ID',
  client_legal_entity_id: 'Client Entity',
  enquiry_type: 'Enquiry Type',
  obligor_id: 'Top Obligor',
  country_id: 'Country',
  contact_id: 'Contact',
  approached: 'Contacted',
  indication_date: 'Date indication',
  mandate_number: 'Mandate ID',
  mandate_name: 'Mandate Name',
  market: 'Enquiry Market',
  risk_trigger: 'Enquiry Risk trigger',
  status: 'Enquiry Status',
  outcome: 'Enquiry Outcome',
  partner_id: 'Partner ID ',
  underwriter_id: 'Underwriter ID',
  channel: 'Channel',
  indication_type: 'Indication',
  contacted_date: 'Date Contacted',
  amount: 'Line',
  currency: 'Currency',
  indication_price: 'Price',
  comments: 'Comments',
  owner_id: 'Enquiry Owner',
  support1_user_id: 'Enquiry Support 1',
  support2_user_id: 'Enquiry Support 2',
  created_at: 'Created Date',
  updated_at: 'Updated Date',
};

type Props = {
  addBtn?: () => void;
  handleFilter?: () => void;
  activeLink: Menu;
  children?: React.ReactNode;
  isShowFiltered?: boolean;
  isNoExport?: boolean;
  isNoFilter?: boolean;
};

const IndicationActionContent: React.FC<Props> = ({
  children,
  isNoFilter = false,
  ...props
}) => {
  const indicationFilter = useAppSelector((state) => state.indicationFilter);
  const [exportIndicationMutation] = useExportIndicationMutation();
  const filterData = useCallback((): FilterType[] => {
    return Object.values(indicationFilter.data).reduce<FilterType[]>((p, e) => {
      if (e.property.toLowerCase().endsWith('key')) {
        return p;
      } else {
        p.push(e);
      }
      return p;
    }, []);
  }, [indicationFilter]);

  const exportIndication = useCallback(
    (values: string[], callback: () => void) => {
      const filters = filterData();
      exportIndicationMutation({
        filter: filters,
        fields: values,
      })
        .then((res) => {
          const rep = res as any;
          if (rep.data) {
            saveAs(
              new Blob([rep.data], { type: 'text/csv;charset=utf-8' }),
              'indication.csv',
            );
          }
        })
        .finally(() => {
          callback();
        });
    },
    [indicationFilter],
  );

  return (
    <>
      <div className="group-filtre">
        <div className="add-group">
          <ul>
            {isCan('create', props.activeLink) ? (
              <li>
                <NavLink className="btn red" to="#" onClick={props.addBtn}>
                  <AddIcon className="pr-2" /> Add
                </NavLink>
              </li>
            ) : (
              <li>
                <span />
              </li>
            )}

            <li className="align-self-end">
              <ul>
                {isCan('extract', Menu.indication) && (
                  <li>
                    <ExportDropdown<typeof ExportObject>
                      values={ExportObject}
                      defaultSelectedColumns={Object.keys(ExportObject) as any}
                      onExport={exportIndication}
                    />
                  </li>
                )}

                {!isNoFilter && (
                  <li>
                    <button
                      type="button"
                      className="btn red pt-1"
                      style={{ height: 38 }}
                      onClick={props.handleFilter}
                    >
                      <img src={Filters} alt="" />
                      Filters
                    </button>
                  </li>
                )}
              </ul>
            </li>
          </ul>
        </div>
      </div>
      {children}
    </>
  );
};

export default IndicationActionContent;
