export const cofarcoFileTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'image/jpeg',
  'image/png',
  'image/gif',
];

export function removeDuplicatesByKey<T>(array: T[], key: keyof T): T[] {
  const seenKeys = new Set<any>(); // A set to keep track of seen keys
  return array.filter((item) => {
    const keyValue = item[key]; // Get the value of the specified key from the item
    if (seenKeys.has(keyValue)) {
      // If the key value has already been seen, skip this item
      return false;
    } else {
      // Otherwise, add the key value to the set and include the item
      seenKeys.add(keyValue);
      return true;
    }
  });
}

export const getNestedValue = (obj: any, path: string): any => {
  return path.split('.').reduce((acc, key) => acc?.[key], obj);
};
