import {
  useRef,
  useState,
  useEffect,
  ChangeEvent,
  SetStateAction,
  Dispatch,
  useCallback,
  UIEventHandler,
} from 'react';
import { Dropdown, FormControl, FormControlProps } from 'react-bootstrap';
import { debounce } from 'lodash';
import { FaChevronDown, FaTimes } from 'react-icons/fa';
import classes from './DropdownSelect.module.scss';
import FormError from '../../FormError/FormError';
import _ from 'lodash';
export type DropDownOptionObjectType = { key: any; value: any };
export type DropDownOptionType = string | DropDownOptionObjectType;
export type DropDownProps = {
  options?: DropDownOptionType[];
  isCountry?: boolean | undefined;
  enableForceUpdate?: boolean | undefined;
  loading?: boolean;
  noPadding?: boolean;
  modalId?: string;
  dropdownWrapperClass?: string;
  preValue?: string;
  allowNew?: boolean;
  error?: string;
  noSearch?: boolean;
  onScroll?: UIEventHandler<HTMLElement> | undefined;
  notDeletable?: boolean;
  emptyLabel?: React.ReactNode;
  showOnlyLoader?: boolean;
  onSelectOption?: (select: DropDownOptionType) => void;
  bottomError?: string;
  onFocus?: () => void;
  onSearch?: (
    dispatch: Dispatch<SetStateAction<DropDownOptionType[]>>,
    value: string,
  ) => void;
  withSafariKeysContacts?: boolean;
  minWidthFormInput?: number;
  resetFormNoPreValue?: boolean;
  menuWidth?: string;
} & FormControlProps;

const DropdownSelect = ({
  options,
  loading,
  modalId,
  allowNew,
  preValue,
  emptyLabel,
  noSearch,
  notDeletable,
  enableForceUpdate,
  dropdownWrapperClass = 'me-3',
  onSelectOption,
  onSearch,
  noPadding,
  onFocus,
  showOnlyLoader = false,
  withSafariKeysContacts = false,
  minWidthFormInput,
  onScroll,
  menuWidth,
  ...props
}: DropDownProps) => {
  const formRef = useRef<HTMLInputElement>();

  const [value, setValue] = useState<string>(preValue ?? '');
  const [tmpValue, setTmpValue] = useState<string>(preValue ?? '');
  const [focus, setFocus] = useState<boolean>(false);
  const [filteredItem, setFilteredItem] = useState<DropDownOptionType[]>([]);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  // keep preValue in a state to prevent the component from re-rendering
  // when the preValue is changed from the parent component

  const [loadingLocal, setLoadingLocal] = useState<boolean>(loading ?? false);

  const debounceSearch = debounce((e) => {
    if (onSearch) {
      onSearch(setFilteredItem, e.target.value);
    }
  }, 750);

  const onFilterItems = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (!noSearch) {
      debounceSearch(e);
      setTmpValue(e.target.value);
      if (allowNew && e.target.value) {
        if (onSelectOption) onSelectOption(e.target.value);
      }
    }
  };

  const onSelectElement = (e: DropDownOptionType) => {
    const value: string =
      typeof e === 'string' ? e : (e as DropDownOptionObjectType).value;
    setValue(value);
    setTmpValue(value);
    if (formRef.current && value !== '') {
      formRef.current.click();
    }
    if (onSelectOption) onSelectOption(e);
  };

  const handleBlurInput = useCallback(() => {
    if (!allowNew) {
      setTmpValue(value);
    }
  }, [value, allowNew]);

  const handleToggle = useCallback(
    (isOpen: boolean) => {
      setFocus(isOpen);
      if (isOpen) {
        if (formRef.current) {
          formRef.current.focus();
        }
      } else {
        if (formRef.current) {
          formRef.current.blur();
        }
      }
    },
    [props.disabled, noSearch, formRef.current],
  );

  useEffect(() => {
    if (options && !enableForceUpdate) {
      setFilteredItem(options.filter((item, _, a) => a.indexOf(item) !== -1));
    }
  }, [options]);

  useEffect(() => {
    if (preValue) {
      setValue(preValue);
      setTmpValue(preValue);
    }
    if (!preValue && props?.resetFormNoPreValue) {
      setValue('');
      setTmpValue('');
    }
  }, [preValue]);

  // wait for 100ms after the loading is done to display the dropdown
  // to wat for setFilteredItem
  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setLoadingLocal(false);
      }, 100);
    } else {
      setLoadingLocal(true);
    }
  }, [loading]);

  return (
    <>
      <Dropdown
        onToggle={(isOpen) => {
          if (props.disabled) return;
          handleToggle(isOpen);
        }}
        {...(withSafariKeysContacts && isSafari
          ? {
              onFocus: () => {
                if (props.disabled) return;
                handleToggle(!focus);
              },
            }
          : {})}
      >
        <div
          className={`${classes.dropdownSelect}  ${
            !modalId && dropdownWrapperClass
          } position-relative ${!noPadding && 'py-2'}`}
        >
          <Dropdown.Toggle
            style={{ position: 'relative' }}
            as="div"
            disabled={props.disabled}
            className={`${classes.dropdownToogle} w-100`}
          >
            <div
              className={`d-flex ${props.error ? classes.formInputError : ''} 
                ${
                  props.disabled ? classes.formInputDisabled : classes.formInput
                }
                 position-relative`}
            >
              <FormControl
                {..._.omit(props, [
                  'onChange',
                  'onFocus',
                  'onBlur',
                  'resetFormNoPreValue',
                ])}
                ref={formRef}
                autoFocus={false}
                autoComplete="off"
                value={tmpValue}
                disabled={props.disabled}
                onChange={(e) => {
                  props.onChange && props.onChange(e);
                  onFilterItems(e);
                }}
                onFocus={() => {
                  if (onFocus) onFocus();
                  if (onSearch && !noSearch && !props.disabled)
                    onSearch(setFilteredItem, '');
                }}
                onBlur={(e) => {
                  props.onBlur && props.onBlur(e);
                  handleBlurInput();
                }}
                className={`${classes.formInputControl}`}
                style={{ minWidth: minWidthFormInput }}
              />

              {!notDeletable && !props.disabled && (
                <FaTimes
                  className={`${
                    value &&
                    typeof value === 'string' &&
                    (value as string)?.trim() !== ''
                      ? classes.closeIcon
                      : classes.closeIconHide
                  }`}
                  onClick={() => {
                    if (value && (value as string).trim() !== '') {
                      onSelectElement('');
                    }
                  }}
                />
              )}

              <span
                className={`${
                  props.disabled
                    ? classes.toogleButtonDisabled
                    : classes.toogleButton
                }`}
                style={{
                  padding: '0.375rem 0.55rem',
                  transform: focus ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.15s ease-in-out',
                }}
              >
                <FaChevronDown
                  style={{
                    color: '#6d1206',
                  }}
                />
              </span>
            </div>
            <FormError
              message={props.error}
              style={{
                position: 'absolute',
                bottom: props.bottomError ?? '-1.35rem',
                left: '0',
              }}
            />
          </Dropdown.Toggle>

          {!props.disabled && (
            <Dropdown.Menu
              popperConfig={{ strategy: 'fixed' }}
              renderOnMount
              className={`${classes.dropdown}`}
              show={focus}
            >
              <div
                className={`${classes.dropdownMenu}`}
                style={{
                  width:
                    menuWidth ||
                    `${(formRef.current?.offsetWidth || 200) + 40}px`,
                  minWidth: '180px',
                  position: 'relative',
                }}
                onScroll={onScroll}
              >
                {(loading || loadingLocal) && (
                  <div
                    className="mx-3"
                    style={{
                      position: 'fixed',
                      top: -3,
                      right: '45%',
                      transform: 'translateX(50%)',
                      width: 'fit-content',
                    }}
                  >
                    {[1, 2, 3].map((e, index) => (
                      <div
                        className="spinner-grow"
                        role="status"
                        key={`${index}-${e.toString()}-${new Date().toDateString()}`}
                        style={{
                          width: '.3rem',
                          height: '.3rem',
                          marginInline: '5px',
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ))}
                  </div>
                )}
                {!showOnlyLoader &&
                  [...filteredItem]?.map((e, index) => {
                    const evalue =
                      typeof e === 'string'
                        ? (e as string)
                        : (e as DropDownOptionObjectType).value;
                    return (
                      <div
                        className={`mx-3 d-block position-relative`}
                        style={{
                          whiteSpace: 'normal',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        key={`${index}-${e.toString()}-${new Date().toDateString()}`}
                        onClick={() => {
                          onSelectElement(e);
                        }}
                      >
                        <span
                          className={` px-3 ${classes.dropdownItem} ${
                            value === evalue && classes.dropdownItemHovered
                          }`}
                        >
                          {evalue}
                        </span>
                      </div>
                    );
                  })}
                {filteredItem.length == 0 && !loadingLocal && !loading && (
                  <div className={`mx-3`}>{emptyLabel ?? 'Not found'}</div>
                )}
              </div>
            </Dropdown.Menu>
          )}
        </div>
      </Dropdown>
    </>
  );
};

export default DropdownSelect;
