import { EntityCofarcoTeam } from '../@types/cofarco-teams/type';
import { LoginResponse } from '../@types/common-types';
import { config } from '../data/config';
import i18n from '../i18n';
import { store } from '../redux/store';

export enum STORAGE_KEY {
  REFRESH_TOKEN = 'refresh_token',
  TOKEN = 'token',
  EXPIRATION_DATE = 'expiration_date',
}

export const setUserSession = (user: LoginResponse) => {
  if (user) {
    localStorage.setItem(STORAGE_KEY.TOKEN, user.token ?? '');
    localStorage.setItem(
      STORAGE_KEY.EXPIRATION_DATE,
      user.expiration_date || '',
    );
    localStorage.setItem(STORAGE_KEY.REFRESH_TOKEN, user?.refreshToken || '');
  }
};

export const clearSession = () => {
  localStorage.removeItem(STORAGE_KEY.TOKEN);
  localStorage.removeItem(STORAGE_KEY.EXPIRATION_DATE);
  localStorage.removeItem(STORAGE_KEY.REFRESH_TOKEN);
};

export const getUserFullName = (user?: Partial<EntityCofarcoTeam>) => {
  return i18n.t('user_full_name', {
    firstName: user?.people?.firstname,
    lastName: user?.people?.lastname,
  });
};

export const getNameAvatarText = (user?: Partial<EntityCofarcoTeam> | null) => {
  return (
    (user?.people?.firstname?.[0] || '') +
    '' +
    (user?.people?.lastname?.[0] || '')
  );
};

export const getToken = () => {
  return (
    store.getState().auth.token ||
    localStorage.getItem(STORAGE_KEY.TOKEN) ||
    config.token
  );
};
