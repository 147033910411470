import React, { useState } from 'react';

import { ReactComponent as Edit } from '../../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../../assets/img/Delete.svg';
import FilterTab from '../../../utils/filter-tab';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import {
  handleRegion,
  isCan,
  limitedText,
} from '../../../utils/helper-function';

import { LegalEntity } from '../../../@types/legal-entities';
import FlagItem from '../../../components/FlagItem/FlagItem';
import { Menu } from '../../../@types/common-types';
import { useGetCountryQuery } from '../../../redux/api/ws/databases/groups';
import { EntityCountry } from '../../../@types/group/types';
import { capitalize } from 'lodash';
import ArrowDropdownIcon from '../../../icons/ArrowDropdownIcon';
import ArrowUpIcon from '../../../icons/ArrowUpIcon';

type DataIndex = keyof LegalEntity;

type IProps = {
  handleSorted?: (key: string, ruls: 'ASC' | 'DESC') => void;
  editLegalEntity?: (id: number, data?: LegalEntity) => void;
  deleteLegalEntity?: (id: number, data?: LegalEntity) => void;
  legalEntity?: Array<LegalEntity>;
  otherColumns?: string[];
};
export default function ColumnsLegalEntity(props?: IProps) {
  const [dropDownFilterState, setDropDownFilterState] =
    useState<boolean>(false);
  const [uncheckedId, setUncheckedId] = useState<Array<number>>([]);

  const onChangeFilterColumn = (key: number | undefined) => {
    const found = uncheckedId?.find((uncheckedTitle) => uncheckedTitle === key);
    if (found) {
      setUncheckedId(uncheckedId.filter((t) => t !== key));
    } else {
      setUncheckedId([...uncheckedId, key || 0]);
    }
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex | 'groupId',
  ): ColumnType<LegalEntity> => ({
    filterDropdown: ({ close }) =>
      FilterTab<LegalEntity>({
        columns: columns,
        dataIndex: dataIndex,
        handleSorted: (dataIndex: string, rule: 'ASC' | 'DESC') =>
          props?.handleSorted?.(dataIndex, rule),
        onChangeFilterColumn: (key: number | undefined) =>
          onChangeFilterColumn(key),
        uncheckedId: uncheckedId,
        close: () => close(),
      }),
    filterIcon: () => {
      return dropDownFilterState ? (
        <ArrowDropdownIcon width={12} height={12} />
      ) : (
        <ArrowUpIcon width={12} height={12} />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      setDropDownFilterState(visible);
    },
    render: (text: string) => <span>{text}</span>,
  });

  const { data: responseCountry } = useGetCountryQuery();

  const countries: Array<EntityCountry> = responseCountry?.country || [];

  const columns: ColumnsType<LegalEntity> = React.useMemo(
    () =>
      [
        {
          title: 'NAME',
          dataIndex: 'name',
          key: 1,
          width: '40%',
          ...getColumnSearchProps('name'),
          render: (text: string) => (
            <div style={{ minWidth: '250px' }}>
              <span>{limitedText(text, 35)}</span>
            </div>
          ),
        },

        {
          title: 'GROUP',
          dataIndex: 'group',
          key: 2,
          width: '30%',
          ...getColumnSearchProps('group_name'),
          render: (text: string, record: Partial<LegalEntity>) => {
            if (record?.group?.name) {
              return (
                <div style={{ minWidth: '150px' }}>
                  <span>{limitedText(record?.group?.name || '', 35)}</span>
                </div>
              );
            }
          },
        },

        {
          title: 'COUNTRY',
          dataIndex: 'country_id',
          ...getColumnSearchProps('country_name'),
          key: 3,
          width: '30%',
          render: (text: string) => {
            const region = handleRegion(countries, text);
            return (
              <div style={{ minWidth: '150px' }}>
                {region && (
                  <FlagItem code={region.code} region_name={region?.name} />
                )}
              </div>
            );
          },
        },
        {
          title: 'TYPE',
          dataIndex: 'type',
          key: 4,
          hidden: !props?.otherColumns?.includes('type'),
          ...getColumnSearchProps('type'),
          render: (type: string) => (
            <div style={{ minWidth: '250px' }}>
              <span>{capitalize(type?.replaceAll('_', ' '))}</span>
            </div>
          ),
        },
        {
          title: '',
          dataIndex: 'id',
          key: 5,
          width: 120,
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          hidden:
            !isCan('write', Menu.legal_entity) &&
            !isCan('delete', Menu.legal_entity),
          render: (text: number, record: LegalEntity) => {
            return (
              <>
                {isCan('write', Menu.legal_entity) && (
                  <span onClick={() => props?.editLegalEntity?.(text, record)}>
                    <i className="rounded-icon">
                      <Edit height="30" />
                    </i>
                  </span>
                )}

                {isCan('delete', Menu.legal_entity) && (
                  <span
                    onClick={() => props?.deleteLegalEntity?.(text, record)}
                  >
                    <i className="rounded-icon">
                      <Delete height="30" />
                    </i>
                  </span>
                )}
              </>
            );
          },
        },
      ].filter((el) => !el.hidden),
    [props?.legalEntity, dropDownFilterState, uncheckedId, props?.otherColumns],
  );

  return { columns, uncheckedId, getColumnSearchProps };
}
