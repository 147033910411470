import { ColumnType, ColumnsType } from 'antd/es/table';
import { ConterParty } from '../../@types/conterParty';
import { useCallback, useMemo, useState } from 'react';
import { updateModalAction } from '../../redux/slice/modalSlice';
import { useAppDispatch } from '../redux';
import { isCan } from '../../utils/helper-function';
import classes from '../../components/Dashboard/Dashboard.module.scss';
import { ReactComponent as Edit } from '../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../assets/img/Delete.svg';
import { Menu } from '../../@types/common-types';
import { People } from '../../@types/group/types';
import { useDeletePeopleMutation } from '../../redux/api/ws/databases/people';
import FilterTab from '../../utils/filter-tab';
import ArrowDropdownIcon from '../../icons/ArrowDropdownIcon';
import ArrowUpIcon from '../../icons/ArrowUpIcon';

const usePeopleResult = () => {
  const dispatch = useAppDispatch();

  const [deletePeople, { isLoading }] = useDeletePeopleMutation();

  const [showmodalDelete, setShowmodalDelete] = useState<boolean>(false);

  const [selectedPeople, setSelectedPeople] = useState<People | null>(null);

  const [dropDownFilterState, setDropDownFilterState] =
    useState<boolean>(false);
  const [uncheckedId, setUncheckedId] = useState<Array<number>>([]);

  const onChangeFilterColumn = (key: number | undefined) => {
    const found = uncheckedId?.find((uncheckedTitle) => uncheckedTitle === key);
    if (found) {
      setUncheckedId(uncheckedId.filter((t) => t !== key));
    } else {
      setUncheckedId([...uncheckedId, key || 0]);
    }
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<People> => ({
    filterDropdown: ({ close }) =>
      FilterTab<People>({
        columns: columns,
        dataIndex: dataIndex,
        handleSorted: () => {},
        onChangeFilterColumn: (key: number | undefined) =>
          onChangeFilterColumn(key),
        uncheckedId: uncheckedId,
        close: () => close(),
      }),
    filterIcon: () => {
      return dropDownFilterState ? (
        <ArrowDropdownIcon width={12} height={12} />
      ) : (
        <ArrowUpIcon width={12} height={12} />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      setDropDownFilterState(visible);
    },
    render: (text: string) => <span>{text}</span>,
  });

  const columns: ColumnsType<People> = useMemo(
    () =>
      [
        {
          title: 'FIRST NAME',
          dataIndex: 'firstname',
          key: 'firstname',
          defaultSortOrder: 'ascend',
          className: classes.fixedSizeHeaderName,
          ...getColumnSearchProps('firstname'),
          render: (value: any) => (
            <div
              style={{
                minWidth: '100px',
                maxWidth: '100px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <b>{value ?? ''}</b>
            </div>
          ),
        },
        {
          title: 'LAST NAME',
          dataIndex: 'lastname',
          key: 'lastname',
          defaultSortOrder: 'ascend',
          ellipsis: true,
          className: classes.fixedSizeHeaderName,
          ...getColumnSearchProps('lastname'),
          render: (value: any) => (
            <div
              style={{
                minWidth: '80px',
                maxWidth: '80px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <b>{value ?? ''}</b>
            </div>
          ),
        },
        {
          title: 'EMAIL',
          dataIndex: 'professional_mail',
          key: 'professional_mail',
          ellipsis: true,
          className: classes.fixedSizeHeader,
          ...getColumnSearchProps('professional_mail'),
          defaultSortOrder: 'ascend',
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          render: (value: any) => (
            <div
              style={{
                minWidth: '150px',
                maxWidth: '150px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <a className={classes.email} href={`mailto:${value}`}>
                <b>{value}</b>
              </a>
            </div>
          ),
        },
        {
          title: 'PHONE NUMBER',
          dataIndex: 'phone_number',
          className: classes.fixedSizeHeaderPhone,
          key: 'phone_number',
          defaultSortOrder: 'ascend',
          ellipsis: true,
          ...getColumnSearchProps('phone_number'),
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          render: (value: any) => (
            <div
              style={{
                minWidth: '100px',
                maxWidth: '100px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <a className={classes.email} href={`tel:${value}`}>
                <b>{value ?? ''}</b>
              </a>
            </div>
          ),
        },
        {
          title: 'LEGAL ENTITY',
          dataIndex: 'legal_entity',
          ellipsis: true,
          key: 'legal_entity',
          className: classes.fixedSizeHeader,
          ...getColumnSearchProps('legal_entity'),
          defaultSortOrder: 'ascend',
          render: (_: any, record: any) => (
            <div
              style={{
                minWidth: '150px',
                maxWidth: '150px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <b>{record.legal_entity?.name}</b>
            </div>
          ),
        },
        {
          title: 'DEPARTMENT',
          dataIndex: 'department',
          key: 'department',
          ...getColumnSearchProps('department'),
          defaultSortOrder: 'ascend',
          className: classes.fixedSizeHeader,
          render: (_: any, record: any) => (
            <div
              style={{
                minWidth: '150px',
                maxWidth: '150px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <b>{record.department}</b>
            </div>
          ),
        },

        {
          title: '',
          key: 'action',
          className: classes.actionRow,
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          width: 120,
          hidden: !isCan('write', Menu.people) && !isCan('delete', Menu.people),
          render: (_: any, record: any) => (
            <div className="d-flex">
              {isCan('write', Menu.people) && (
                <span
                  className={`mx-1 rounded-icon  ${classes.action}`}
                  onClick={() => editPeople(record)}
                >
                  <Edit height="30" />
                </span>
              )}
              {isCan('delete', Menu.people) && (
                <span
                  className={`mx-1 rounded-icon ${classes.action}`}
                  onClick={() => handleDelete(record)}
                >
                  <Delete height="30" />
                </span>
              )}
            </div>
          ),
        },
      ].filter((el) => !el.hidden) as ColumnsType<People>,
    [uncheckedId, dropDownFilterState],
  );

  const editPeople = (record: ConterParty) => {
    dispatch(
      updateModalAction({
        data: {
          ...record,
          event: 'edit',
        },
        isAdd: true,
        type: 'people',
      }),
    );
  };

  const deletePeopleCallback = useCallback(() => {
    if (selectedPeople) {
      deletePeople(selectedPeople.id).then(() => {
        setShowmodalDelete(false);
      });
    }
  }, [selectedPeople, setShowmodalDelete]);

  const handleDelete = useCallback(
    (record: People) => {
      setSelectedPeople(record);
      setShowmodalDelete(true);
    },
    [setShowmodalDelete, setSelectedPeople],
  );

  return {
    columns,
    editPeople,
    deletePeopleCallback,
    setShowmodalDelete,
    showmodalDelete,
    selectedPeople,
    setSelectedPeople,
    handleDelete,
    isLoadingDelete: isLoading,
    uncheckedId,
  };
};

export default usePeopleResult;

type DataIndex = keyof People | 'legal_entity';
