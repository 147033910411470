export const url = {
  login: 'auth/login',
  refreshToken: 'auth/refresh',
  group: {
    list: 'group',
    export: 'export/group',
  },
  legalEntity: {
    list: 'legalentity',
    check_iban: 'bankaccount',
    export: 'export/legalentity',
    counterpartyByLegalEntity: 'list/counterpartyByLegalEntity',
  },
  people: {
    list: 'people',
    export: 'export/people',
  },
  cofarco_teams: {
    list: 'user',
    avatar: 'user/uploadAvatar',
    export: 'export/user',
  },
  counterparty: {
    list: 'counterparty',
    export: 'export/counterparty',
  },
  segregatedAccounts: {
    notes: 'note',
    exportNotes: 'export/note',
    exportPayement: 'export/payment',
    payement: 'payment',
    bank_account: 'bankaccount',
  },
  mandate: {
    list: 'mandate',
    owner_names: 'user/names',
    export: 'export/mandate',
  },
  enquiries: {
    list: 'enquiry',
    export: 'export/enquiry',
    get_attachement: 'attachment/Enquiry',
  },
  deals: {
    list: 'deal',
    export: 'export/deal',
    get_attachement: 'attachment/Deal',
    events: 'v2/deals',
  },
  legalentity: 'legalentity',
  country: 'country',
  legalEntityInGroupe: 'group/',
  indication: {
    list: 'indication',
  },
  participation: {
    list: 'participation',
    export: 'export/participation',
  },
  limit: {
    edit: 'limit',
    delete: 'limit',
    list: 'limit',
    export: 'export/limit',
  },
  limit_count: 'limit/count',
  pipe: {
    list: 'pipe',
    export: 'export/pipe',
  },
  history: {
    list: 'history',
    detail: 'history/details',
  },
  global: {
    search: 'search',
  },
  dashboard: {
    rev: 'income',
    rum: 'dashboard/rum',
    rue: 'dashboard/rue',
    transactions: 'dashboard/transactions',
    clients: 'dashboard/clients',
    markets: 'dashboard/markets',
    reminder: 'dashboard/reminder/',
    whatsNew: 'dashboard/whatsNew',
    whatsNewSeen: 'dashboard/whatsNewSeen',
    seen: 'dashboard/seen',
    outlookTodayAgenda: 'dashboard/outlookTodayAgenda',
    currencyExchangeRate: 'currencyExchangeRate',
    industryNews: 'dashboard/industryNews',
  },
  attachment: 'attachment',
};

//2/legalentity
