import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Method } from '../../../../@types/common-types';
import {
  BusinessType,
  ConterPartyResponse,
} from '../../../../@types/conterParty';
import {
  EnquiriesResponse,
  EntityAttachementEnquiry,
  EntityEnquiries,
  EntityEnquiryMarket,
  FormIndicationInMarket,
  InputFormEnquiries,
  SingleEnquiriesResponse,
  TattachDealsToEnquiry,
} from '../../../../@types/enquiries';
import { LegalEntity } from '../../../../@types/legal-entities';
import {
  EntityMandate,
  MandatesResponse,
} from '../../../../@types/mandates/mandates';
import { ConterParty } from '../../../../@types/conterParty';
import { baseApi } from '../../baseApi';
import { url } from '../../url';
import { People } from '../../../../@types/people';
import { Indication } from '../../../../@types/Indication/indication';
import { datas } from '../../back-end-service';
import { invalidTags } from '../../../../utils/helper-function';

const enquiriesEntity = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      enquiries: build.query<
        EnquiriesResponse,
        {
          page: number | undefined;
          start: number | undefined;
          limit: number | undefined;
          sort: string;
          filter: string;
        }
      >({
        query: (queryParams) => {
          const { start, page, limit, sort, filter } = queryParams;
          return {
            url: url.enquiries.list,
            params: {
              start,
              page,
              limit,
              sort,
              filter,
              getObligor: true,
              getClient: true,
            },
          };
        },
        providesTags: ['LIST_ENQUIRIES'],
      }),

      getEnquiry: build.query<
        SingleEnquiriesResponse,
        {
          id: string | number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `${url.enquiries.list}/${id}`,
            params: {
              getSupportIds: true,
              getBrokerIds: true,
            },
          };
        },
      }),

      searchClient: build.mutation<
        ConterPartyResponse,
        { page: number; start: number; limit: number; query: any }
      >({
        query: (body) => {
          const { start, page, limit, query } = body;
          return {
            url: url.counterparty.list,
            params: { start, page, limit, query, getGroup: 1 },
            method: Method.GET,
          };
        },
      }),

      exportEnquiries: build.mutation<
        string,
        { fields?: string[]; filter: any }
      >({
        query: (queryParams) => {
          return {
            url: url.enquiries.export,
            method: Method.POST,
            data: queryParams,
          };
        },
      }),

      deleteEnquiries: build.mutation<
        { success: boolean; enquiries: EnquiriesResponse },
        { id: number }
      >({
        query: (body) => {
          return {
            url: url.enquiries.list + '/' + body.id,
            method: Method.DELETE,
          };
        },
        invalidatesTags: invalidTags,
      }),

      searchMandateNameInEnquiries: build.mutation<
        MandatesResponse,
        { query: any }
      >({
        query: (body) => {
          const { query } = body;
          return {
            url: url.mandate.list,
            params: {
              start: 0,
              page: 1,
              limit: 100,
              query,
              withClientAndName: true,
              status: 'executing',
              types: 'financing,insurance',
              phase: 'mandate',
            },
            method: Method.GET,
          };
        },
      }),

      getLegalEntityByConterPartId: build.mutation<
        { legalentity: Array<LegalEntity>; success: string },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.legalEntity.list}`,
            params: {
              _dc: new Date().getTime(),
              counterpartyId: id,
              getGroup: true,
              getLloydsStatus: 1,
              page: 1,
              start: 0,
              limit: 25,
            },
          };
        },
      }),

      getMandateByLegalEntity: build.mutation<
        { mandate: EntityMandate; success: string },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.mandate.list}/${id}`,
            params: {
              _dc: new Date().getTime(),
              getClient: true,
              getObligor: true,
              withClientAndName: true,
            },
          };
        },
      }),

      createEnquiries: build.mutation<
        { success: boolean; enquiry: EntityEnquiries },
        InputFormEnquiries
      >({
        query: (body) => {
          return {
            url: url.enquiries.list,
            method: Method.POST,
            data: body,
          };
        },
        invalidatesTags: invalidTags,
      }),

      updateEnquiries: build.mutation<
        { success: boolean; enquiry: EntityEnquiries },
        InputFormEnquiries
      >({
        query: (body) => {
          return {
            url: url.enquiries.list + '/' + body.id,
            method: Method.PUT,
            data: body,
          };
        },
        invalidatesTags: invalidTags,
      }),

      getCounterPartyPartnerById: build.mutation<
        { counterparty: ConterParty; success: string },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.counterparty.list}/${id}`,
            params: {
              _dc: new Date().getTime(),
              businessType: 'partner',
              page: 1,
              start: 0,
              limit: 25,
            },
          };
        },
      }),

      getPeopleByCounterPartyId: build.mutation<
        { people: Array<People>; success: string },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.people.list}`,
            params: {
              _dc: new Date().getTime(),
              counterpartyId: id,
              allEntities: true,
              page: 1,
              start: 0,
              limit: 100,
            },
          };
        },
      }),

      getEnquiriesByMandateId: build.query<
        { enquiry: Array<EntityEnquiries>; success: string },
        {
          id: string | number;
          query?: string;
          filter?: string;
          sort?: string;
          limit?: number;
        }
      >({
        query: ({ id, ...params }) => {
          return {
            url: `${url.enquiries.list}`,
            params: {
              ...params,
              _dc: new Date().getTime(),
              mandateId: id,
              getObligor: true,
              getClient: true,
              page: 1,
              start: 0,
              limit: 100,
            },
          };
        },
        providesTags: ['ENQUIRY_LISTE_BY_MANDATE'],
      }),

      getIndicationById: build.query<
        { indication: Array<EntityEnquiryMarket>; success: string },
        { id: string | number; sort?: string; filter?: string }
      >({
        query: ({ id, sort, filter }) => {
          return {
            url: `${url.indication.list}`,
            params: {
              _dc: new Date().getTime(),
              enquiryId: id,
              getUnderwriter: true,
              getContact: true,
              sort,
              filter,
              page: 1,
              start: 0,
              //limit: 25,
            },
          };
        },
        providesTags: ['INDICATION_MARKET_LIST'],
      }),

      updateIndicationByEnquiryId: build.mutation<
        { indication: Indication; success: string },
        {
          id: number;
          enquiryId: number;
          approached?: boolean;
          contact_id?: number;
          contacted_date?: string;
          indication_date?: string;
          indication_type?: string;
          channel?: string;
          indication_price?: string;
          amount?: string;
          comments?: string;
        }
      >({
        query: ({
          id,
          enquiryId,
          approached,
          contact_id,
          contacted_date,
          indication_date,
          indication_type,
          channel,
          indication_price,
          amount,
          comments,
        }) => {
          return {
            url: `${url.indication.list}/${id}`,
            params: {
              enquiryId: enquiryId,
              getUnderwriter: true,
              getContact: true,
            },
            method: Method.PUT,
            data: {
              approached,
              contact_id,
              contacted_date,
              indication_date,
              indication_type,
              channel,
              indication_price,
              amount,
              comments,
              id: id,
            },
          };
        },
        invalidatesTags: ['INDICATION_MARKET_LIST'],
      }),

      exportIndicationByEnquiry: build.mutation<
        string,
        { fields?: string[]; enquiryId: number }
      >({
        query: ({ fields, enquiryId }) => {
          return {
            url: `export/${url.indication.list}`,
            data: {
              fields,
              enquiryId,
            },
            method: Method.POST,
          };
        },
      }),

      deleteIndicationInEnquiry: build.mutation<
        { success: boolean },
        { id: number }
      >({
        query(arg) {
          return {
            url: `${url.indication.list}/${arg.id}`,
            params: {
              _dc: new Date().getTime(),
            },
            method: Method.DELETE,
          };
        },
        invalidatesTags: ['INDICATION_MARKET_LIST'],
      }),

      uploadEnquiryAttachement: build.mutation<
        { success: boolean; attachment: EntityAttachementEnquiry },
        { id: string | number; data: any }
      >({
        query: (body) => {
          return {
            url: url.enquiries.get_attachement + '/' + body.id,
            method: Method.POST,
            data: datas({ item: body.data }, 'multipart/form-data'),
          };
        },
      }),

      getEnquiryAttachement: build.query<
        { attachment: Array<EntityAttachementEnquiry>; success: string },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.enquiries.get_attachement}/${id}`,
            params: {
              _dc: new Date().getTime(),
            },
          };
        },
        providesTags: ['ATTACHEMENT_ENQUIRY'],
      }),

      deleteEnquiryAttachment: build.mutation<
        { success: boolean },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `attachment/${id}`,
            method: Method.DELETE,
          };
        },
        invalidatesTags: ['ATTACHEMENT_ENQUIRY'],
      }),

      searchCounterPartyMarket: build.mutation<
        ConterPartyResponse,
        {
          businessType: BusinessType;
          page: number;
          start: number;
          limit?: number;
          query: any;
        }
      >({
        query: (body) => {
          const { start, page, limit, query, businessType } = body;
          return {
            url: url.counterparty.list,
            params: {
              start,
              page,
              limit,
              query,
              businessType,
            },
            method: Method.GET,
          };
        },
      }),

      addMarket: build.mutation<ConterPartyResponse, FormIndicationInMarket>({
        query: (body) => {
          return {
            url: url.indication.list,
            method: Method.POST,
            data: body,
          };
        },
        invalidatesTags: ['INDICATION_MARKET_LIST'],
      }),

      searchEnquiryInMarket: build.mutation<
        EnquiriesResponse,
        { query: string }
      >({
        query: (body) => {
          const { query } = body;
          return {
            url: url.enquiries.list,
            params: {
              query,
              withClientAndMandate: true,
              mandateType: 'insurance',
              page: 1,
              start: 0,
              limit: 100,
            },
          };
        },
      }),

      attachDealsToEnquiry: build.mutation<
        EnquiriesResponse,
        TattachDealsToEnquiry
      >({
        query: ({ enquiryId, ...body }) => {
          return {
            url: `${url.enquiries.list}/${enquiryId}/deals`,
            method: Method.PUT,
            data: body,
          };
        },
        invalidatesTags: ['DEAL_LIST_BY_MANDATE'],
      }),
    };
  },
});

export const {
  useEnquiriesQuery,
  useLazyEnquiriesQuery,
  useExportEnquiriesMutation,
  useDeleteEnquiriesMutation,
  useSearchClientMutation,
  useSearchMandateNameInEnquiriesMutation,
  useGetLegalEntityByConterPartIdMutation,
  useGetMandateByLegalEntityMutation,
  useCreateEnquiriesMutation,
  useGetCounterPartyPartnerByIdMutation,
  useUpdateEnquiriesMutation,
  useGetIndicationByIdQuery,
  useGetPeopleByCounterPartyIdMutation,
  useGetEnquiriesByMandateIdQuery,
  useLazyGetEnquiriesByMandateIdQuery,
  useUpdateIndicationByEnquiryIdMutation,
  useExportIndicationByEnquiryMutation,
  useDeleteIndicationInEnquiryMutation,
  useUploadEnquiryAttachementMutation,
  useGetEnquiryAttachementQuery,
  useDeleteEnquiryAttachmentMutation,
  useSearchCounterPartyMarketMutation,
  useAddMarketMutation,
  useSearchEnquiryInMarketMutation,
  useLazyGetIndicationByIdQuery,
  useAttachDealsToEnquiryMutation,
  useGetEnquiryQuery,
} = enquiriesEntity;
