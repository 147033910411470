import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { ContentType, Method } from '../../../../@types/common-types';
import { ConterPartyResponse } from '../../../../@types/conterParty';
import { PeopleResponse } from '../../../../@types/people';
import {
  EntityNotes,
  FilterNoteInput,
  InputFormsNotes,
  InputFormsNotesDetails,
  InputPayemenySegregated,
  NoteAttachementType,
  NotesResponse,
} from '../../../../@types/segregatedaccounts/notes';

import { baseApi } from '../../baseApi';
import { url } from '../../url';
import { PayementResponse } from '../../../../@types/segregatedaccounts/payements';
import { datas } from '../../back-end-service';

const notes = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      notes: build.query<NotesResponse, FilterNoteInput>({
        query: (queryParams) => {
          return {
            url: url.segregatedAccounts.notes,
            params: {
              getLegalEntity: 1,
              getDeal: 1,
              segregated: 1,
              page: 1,
              limit: 100,
              start: 0,
              ...queryParams,
            },
          };
        },
        providesTags: ['LIST_NOTES'],
      }),
      filteredNotesAttachedInNotes: build.mutation<
        NotesResponse,
        {
          query: string;

          params?: Record<string, any>;
        }
      >({
        query: (queryParams) => {
          const { query, params } = queryParams;
          const parameters = { ...(params || {}), query };

          return {
            url: url.segregatedAccounts.notes,
            method: Method.GET,
            params: {
              getLegalEntity: true,
              getCompleteRef: true,
              page: 1,
              start: 0,
              limit: 250,
              ...parameters,
            },
          };
        },
      }),

      deleteNote: build.mutation<{ success: boolean }, { id: number }>({
        query: (body) => {
          return {
            url: url.segregatedAccounts.notes + '/' + body.id,
            method: Method.DELETE,
          };
        },
        invalidatesTags: ['LIST_NOTES', 'GLOBAL_RESULT_SEARCH'],
      }),
      searchCounterPartByName: build.mutation<
        ConterPartyResponse,
        { query: any }
      >({
        query: (body) => {
          const { query } = body;
          return {
            url: url.counterparty.list,
            params: {
              start: 0,
              page: 1,
              limit: 25,
              getFullName: true,
              onlyWithNotes: true,
              query,
            },
            method: Method.GET,
          };
        },
      }),
      exportNotes: build.mutation<string, { fields?: string[]; filter: any }>({
        query: (queryParams) => {
          return {
            url: url.segregatedAccounts.exportNotes,
            method: Method.POST,
            data: queryParams,
          };
        },
      }),
      getPeopleInNote: build.mutation<
        PeopleResponse,
        { noteType: string; dealId: number; counterpartyId: number | string }
      >({
        query: (body) => {
          const { noteType, dealId, counterpartyId } = body;
          return {
            url: url.people.list,
            params: {
              noteType,
              dealId,
              counterpartyId,
              page: 1,
              start: 0,
              limit: 100,
            },
            method: Method.GET,
          };
        },
      }),

      createNote: build.mutation<
        { success: boolean; note: EntityNotes },
        InputFormsNotes
      >({
        query: (body) => {
          return {
            url: url.segregatedAccounts.notes,
            method: Method.POST,
            data: body,
            params: {
              getLegalEntity: true,
              getCompleteRef: true,
              getDeal: true,
              segregated: 1,
            },
          };
        },
        invalidatesTags: ['LIST_NOTES'],
      }),

      filteredPayement: build.mutation<
        PayementResponse,
        {
          query: string;
        }
      >({
        query: (queryParams) => {
          const { query } = queryParams;
          return {
            url: url.segregatedAccounts.payement,
            method: Method.GET,
            params: {
              query,
              getLegalEntity: true,
              getCompleteRef: true,
              filter: [
                { operator: '=', property: 'status', value: 'unassigned' },
              ],
              page: 1,
              start: 0,
              limit: 100,
            },
          };
        },
      }),

      getPayementByNotesId: build.mutation<PayementResponse, { id: number }>({
        query: (body) => {
          const { id } = body;
          return {
            url: url.segregatedAccounts.payement,
            params: {
              getLegalEntity: true,
              getCompleteRef: true,
              noteId: id,
              page: 1,
              start: 0,
              limit: 100,
            },
            method: Method.GET,
          };
        },
      }),

      getNotesByNoteId: build.mutation<
        NotesResponse,
        { noteId: number; attachedType?: NoteAttachementType }
      >({
        query: (body) => {
          return {
            url: url.segregatedAccounts.notes,
            params: {
              getLegalEntity: true,
              getCompleteRef: true,
              page: 1,
              start: 0,
              limit: 100,
              ...body,
            },
            method: Method.GET,
          };
        },
      }),

      getAttachedByNoteId: build.query<NotesResponse, { id: number }>({
        query: (body) => {
          const { id } = body;
          return {
            url: url.segregatedAccounts.notes,
            params: {
              getLegalEntity: true,
              getCompleteRef: true,
              noteId: id,
              page: 1,
              start: 0,
              limit: 100,
            },
            method: Method.GET,
          };
        },
      }),

      updateNotes: build.mutation<
        { success: boolean; note: InputFormsNotesDetails },
        EntityNotes
      >({
        query: (body) => {
          return {
            url: url.segregatedAccounts.notes + '/' + body.id,
            method: Method.PUT,
            data: body,
            params: {
              getLegalEntity: true,
              getBankAccountOwner: true,
              segregated: 1,
              getDeal: true,
            },
          };
        },
        invalidatesTags: ['LIST_NOTES', 'GLOBAL_RESULT_SEARCH'],
      }),

      postPayementInNote: build.mutation<
        PayementResponse,
        { payments: Array<InputPayemenySegregated>; id: number }
      >({
        query: (body) => ({
          url: `${url.segregatedAccounts.notes}/${body.id}/payments`,
          method: Method.POST,
          data: datas(
            {
              payments: JSON.stringify(body.payments),
            },
            ContentType.FORM_DATA,
          ),
        }),
        invalidatesTags: ['LIST_NOTES', 'GLOBAL_RESULT_SEARCH'],
      }),

      attachNotes: build.mutation<
        NotesResponse,
        {
          noteId: number;
          netOffNoteId: number;
          creditNotes: string;
        }
      >({
        query: ({ noteId, netOffNoteId, creditNotes }) => ({
          url: `${url.segregatedAccounts.notes}/${noteId}/notes`,
          method: Method.POST,
          headers: {
            'Content-Type': ContentType.JSON,
          },
          data: datas(
            {
              netOffNoteId,
              creditNotes,
            },
            ContentType.JSON,
          ),
        }),
        invalidatesTags: ['LIST_NOTES', 'GLOBAL_RESULT_SEARCH'],
      }),

      deleteAttachedNote: build.mutation<
        { success: boolean },
        { noteAttachedId: any; noteId: number }
      >({
        query: ({ noteAttachedId, noteId }) => {
          return {
            url: url.segregatedAccounts.notes + '/' + noteId + '/note_detach',
            method: Method.POST,
            data: datas(
              {
                note_id: noteAttachedId,
              },
              ContentType.FORM_DATA,
            ),
          };
        },
        invalidatesTags: ['LIST_NOTES', 'GLOBAL_RESULT_SEARCH'],
      }),

      signedOffNote: build.mutation<
        PayementResponse,
        { id: number; status: 'past_due' | 'raised' }
      >({
        query: (data) => ({
          url: `${url.segregatedAccounts.notes}/${data.id}`,
          method: Method.PUT,
          params: {
            getLegalEntity: true,
            getDeal: true,
            segregated: 1,
          },
          data: {
            id: data.id,
            status: data.status,
          },
        }),
        invalidatesTags: ['LIST_NOTES', 'GLOBAL_RESULT_SEARCH'],
      }),
    };
  },
});
export const {
  useNotesQuery,
  useLazyNotesQuery,
  useDeleteNoteMutation,
  useSearchCounterPartByNameMutation,
  useExportNotesMutation,
  useGetPeopleInNoteMutation,
  useCreateNoteMutation,
  useFilteredPayementMutation,
  useFilteredNotesAttachedInNotesMutation,
  useGetPayementByNotesIdMutation,
  useUpdateNotesMutation,
  usePostPayementInNoteMutation,
  useSignedOffNoteMutation,
  useAttachNotesMutation,
  useGetNotesByNoteIdMutation,
  useDeleteAttachedNoteMutation,
  useGetAttachedByNoteIdQuery,
} = notes;
