export const exportedDeals = [
  {
    itemId: 'number',
    text: 'ID',
  },

  {
    itemId: 'client_id',
    text: 'Client',
  },
  {
    itemId: 'client_legal_entity_id',
    text: 'Client Entity',
  },
  {
    itemId: 'deal_type',
    text: 'Type',
  },
  {
    itemId: 'status',
    text: 'Status',
  },
  {
    itemId: 'obligor_id',
    text: 'Obligor',
  },

  {
    itemId: 'country_id',
    text: 'Top Obligor Country',
  },
  {
    itemId: 'lead_line_id',
    text: 'Lead Financier/Underwriter',
  },
  {
    itemId: 'amount',
    text: 'Deal Amount',
  },
  {
    itemId: 'currency',
    text: 'Deal Currency',
  },
  {
    itemId: 'expiry_date',
    text: 'Deal Expiry',
  },
  {
    itemId: 'owner',
    text: 'Owner',
  },

  {
    itemId: 'mandate_number',
    text: 'Mandate ID',
  },
  {
    itemId: 'mandate_name',
    text: 'Mandate Name',
  },

  {
    itemId: 'market',
    text: 'Market',
  },
  {
    itemId: 'risk_trigger',
    text: 'Risk Trigger',
  },

  {
    itemId: 'structure',
    text: 'Structure',
  },

  {
    itemId: 'partner_id',
    text: 'Partner',
  },
  {
    itemId: 'partner_legal_entity_id',
    text: 'Partner Entity',
  },
  {
    itemId: 'partner_contact_id',
    text: 'Partner Contact',
  },
  {
    itemId: 'partner_reference',
    text: 'Partner Reference Number',
  },
  {
    itemId: 'booking',
    text: 'Booking',
  },

  {
    itemId: 'inception_date',
    text: 'Deal Inception',
  },

  {
    itemId: 'non_renewal_notice_date',
    text: 'Non Renewal Notice',
  },
  {
    itemId: 'insured',
    text: 'Insured Percentage',
  },
  {
    itemId: 'insured_gross',
    text: 'Insured Gross Exposure',
  },
  {
    itemId: 'individual_limits',
    text: 'Aggregate Individual Limits',
  },
  {
    itemId: 'deductible',
    text: 'Deductible',
  },
  {
    itemId: 'risk_mode',
    text: 'Risk Mode',
  },
  {
    itemId: 'calculation_basis',
    text: 'Premium Calculation Basis',
  },
  {
    itemId: 'premium_rate',
    text: 'Risk Premium',
  },
  {
    itemId: 'minimum_deposit',
    text: 'Minimum Deposit Premium',
  },

  {
    itemId: 'owner_role',
    text: 'Cofarco Role',
  },
  {
    itemId: 'partner_role',
    text: 'Partner Role',
  },
  {
    itemId: 'gross_brokerage',
    text: 'Gross Brokerage',
  },
  {
    itemId: 'net_brokerage',
    text: 'Net Brokerage',
  },
  {
    itemId: 'created_at',
    text: 'Created Date',
  },
  {
    itemId: 'updated_at',
    text: 'Updated Date',
  },
];

export const getDealCartridgeStatus = (status: string) => {
  switch (status.trim().toLowerCase()) {
    case 'bound':
      return 'nbi';
    case 'binding':
      return 'pending';
    case 'ended':
      return 'nty';
    case 'aborted':
      return 'nr';
    default:
      return status;
  }
};
