const retrieveStoredSession = () => {
  return localStorage.getItem('token') ?? '';
};

export const ORIGIN_LOCATION =
  window && window.location && window.location.origin;

export const API_URL = process.env.REACT_APP_API_URL;

export const config = {
  baseUrl: API_URL || '',
  token: retrieveStoredSession(),
};
