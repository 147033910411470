import ModalChangePassword from '../common/Modal/ModalChangePassword';
import { Button, Menu, Popover, Space, Typography } from 'antd';

import {
  MdKeyboardArrowDown,
  MdOutlineLogout,
  MdPassword,
} from 'react-icons/md';
import UserAvatar from '../common/Avatar/UserAvatar';
import './Navbar.scss';
import useNavbar from './hooks/useNavbar';

const NavBar = () => {
  const {
    avatarPath,
    userName,
    openChangePswd,
    dataCurrencies,
    authData,
    avatarKey,
    onChangeAvatar,
    handleChangePassword,
    handleLogOut,
    setOpenChangePswd,
  } = useNavbar();

  const menuItems = [
    {
      key: '1',
      label: 'Modify Password',
      icon: <MdPassword fontSize="larger" color="white" />,
      onClick: handleChangePassword,
    },
    {
      key: '2',
      label: 'Logout',
      icon: <MdOutlineLogout fontSize="larger" color="white" />,
      onClick: handleLogOut,
    },
  ];
  return (
    <div className="d-flex headRightSide">
      <Space wrap className="dashboard-currencies">
        {['EUR/USD', 'EUR/CHF', 'GBP/EUR'].map((exc, index) => {
          const couple = exc.split('/');
          const data = (dataCurrencies?.data || []).find((c) => {
            const from = c.currencyFrom.toLowerCase();
            const to = c.currencyTo.toLowerCase();
            const okFrom = from === couple[0].toLowerCase();
            const okTo = to === couple[1].toLowerCase();
            return okFrom && okTo;
          });
          if (!data) return '';
          return (
            <span
              key={`currency-nav-bar-${index}`}
              style={{ textTransform: 'uppercase' }}
              data-testid={`currency-nav-bar-${index}`}
            >
              {data?.currencyFrom}/{data?.currencyTo} {data?.rate.toFixed(6)}
            </span>
          );
        })}
      </Space>
      <div className="myAccount">
        <Popover
          content={<Menu items={menuItems} />}
          trigger="click"
          overlayClassName="nav-bar-popover"
          arrow={false}
        >
          <Button
            type="text"
            className="d-flex align-items-center gap-1 p-0"
            style={{ height: 'auto' }}
          >
            <UserAvatar
              key={avatarKey}
              src={avatarPath}
              onClick={() => {
                document.getElementById('avatar')?.click();
              }}
              alt="cofarco team avatar"
              user={authData?.user}
            />
            <Typography color="GrayText">{userName}</Typography>
            <MdKeyboardArrowDown fontSize="1.5em" />
          </Button>
        </Popover>
      </div>

      <input
        className="d-none"
        type="file"
        id="avatar"
        accept="image/*"
        onChange={onChangeAvatar}
      />

      <ModalChangePassword
        showModalPassword={openChangePswd}
        handleLeave={() => setOpenChangePswd(false)}
        handleCancel={() => setOpenChangePswd(false)}
        user={authData?.user}
      />
    </div>
  );
};

export default NavBar;
