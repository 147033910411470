import { SVGProps } from 'react';

const LoadingIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58px"
      height="28px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      {...props}
    >
      <rect x="20.5" y="28" width="9" height="20" fill="#565A61">
        <animate
          attributeName="y"
          repeatCount="indefinite"
          dur="1.282051282051282s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="14.799999999999997;28;28"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.25641025641025644s"
        ></animate>
        <animate
          attributeName="height"
          repeatCount="indefinite"
          dur="1.282051282051282s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="70.4;44;44"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.25641025641025644s"
        ></animate>
      </rect>
      <rect x="45.5" y="28" width="9" height="20" fill="#ffffff">
        <animate
          attributeName="y"
          repeatCount="indefinite"
          dur="1.282051282051282s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="18.099999999999994;28;28"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.12820512820512822s"
        ></animate>
        <animate
          attributeName="height"
          repeatCount="indefinite"
          dur="1.282051282051282s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="63.80000000000001;44;44"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.12820512820512822s"
        ></animate>
      </rect>
      <rect x="70.5" y="28" width="9" height="20" fill="#eb5d57">
        <animate
          attributeName="y"
          repeatCount="indefinite"
          dur="1.282051282051282s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="18.099999999999994;28;28"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        ></animate>
        <animate
          attributeName="height"
          repeatCount="indefinite"
          dur="1.282051282051282s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="63.80000000000001;44;44"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        ></animate>
      </rect>
    </svg>
  );
};

export default LoadingIcon;
