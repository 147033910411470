import { SVGProps } from 'react';

const ArrowUpIcon = ({ color, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="9"
      height="5"
      viewBox="0 0 9 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.97527 4.62638C7.86249 4.73913 7.70954 4.80248 7.55006 4.80248C7.39058 4.80248 7.23763 4.73913 7.12484 4.62638L4.14774 1.64927L1.17063 4.62638C1.0572 4.73594 0.905271 4.79656 0.747577 4.79519C0.589882 4.79382 0.439034 4.73057 0.327523 4.61906C0.216012 4.50755 0.15276 4.3567 0.15139 4.199C0.15002 4.04131 0.210641 3.88939 0.320197 3.77595L3.72252 0.373629C3.83531 0.260877 3.98826 0.197536 4.14774 0.197536C4.30721 0.197536 4.46016 0.260877 4.57295 0.373629L7.97527 3.77595C8.08803 3.88874 8.15137 4.04169 8.15137 4.20117C8.15137 4.36065 8.08803 4.5136 7.97527 4.62638Z"
        fill={color || '#53585F'}
      />
    </svg>
  );
};

export default ArrowUpIcon;
